define("ember-body-class/util/bodyClass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addClass = addClass;
  _exports.removeClass = removeClass;
  /* eslint-disable prettier/prettier */
  function addClass(element, klass) {
    if (typeof FastBoot === 'undefined') {
      element.classList.add(klass);
    } else {
      var existingClass = element.getAttribute('class') || '';
      if (existingClass) {
        var classes = existingClass.split(' ');
        if (classes.includes(klass)) {
          return;
        }
        element.setAttribute('class', "".concat(existingClass, " ").concat(klass));
      } else {
        element.setAttribute('class', klass);
      }
    }
  }
  function removeClass(element, klass) {
    if (typeof FastBoot === 'undefined') {
      element.classList.remove(klass);
    } else {
      var existingClass = element.getAttribute('class');
      element.setAttribute('class', existingClass.replace(klass, ''));
    }
  }
});