define("ember-countries/utils/helpers", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildIndex = buildIndex;
  _exports.getFormat = getFormat;
  function getFormat(val) {
    if (typeof val !== "string") {
      return null;
    }
    if (!isNaN(Number(val))) {
      return 'isoNumeric';
    } else if (val.length === 2) {
      return 'iso2';
    } else if (val.length === 3) {
      return 'iso3';
    } else {
      return null;
    }
  }
  function buildIndex(LIST, code, indexedObj) {
    if (!(0, _utils.isNone)(indexedObj)) {
      return indexedObj;
    }
    indexedObj = LIST.reduce(function (prev, el) {
      prev[el[code]] = el;
      return prev;
    }, {});
    return indexedObj;
  }
});