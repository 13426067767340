define("ember-svg-jar/inlined/dropdown-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M10 0L4.998 6 0 0z\" fill=\"#2F728D\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "class": "dropdown-arrow",
      "width": "10",
      "height": "6",
      "viewBox": "0 0 10 6",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});