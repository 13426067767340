define("ember-svg-jar/inlined/icon-shipment-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M4.75 11a.75.75 0 000 1.5H12a.75.75 0 000-1.5H4.75zM4 9a.75.75 0 01.75-.75h9.5a.75.75 0 010 1.5h-9.5A.75.75 0 014 9zM5 4.5a1 1 0 00-1 1V6a1 1 0 001 1h10.5a1 1 0 001-1v-.5a1 1 0 00-1-1H5z\" fill=\"#000\"/><path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.962.985C1.26.675 1.66.5 2.08.5H18.92c.419 0 .82.174 1.117.485.296.31.462.731.462 1.17v12.69c0 .439-.166.86-.462 1.17-.297.31-.698.485-1.117.485H2.08c-.419 0-.82-.174-1.117-.485a1.697 1.697 0 01-.462-1.17V2.155c0-.439.166-.86.462-1.17zm17.642 1.661a.5.5 0 00-.354-.146H2.75a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h15.5a.5.5 0 00.5-.5V3a.5.5 0 00-.146-.354z\" fill=\"#000\"/>",
    "attrs": {
      "width": "21",
      "height": "18",
      "viewBox": "0 0 21 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});