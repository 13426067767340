define("ember-svg-jar/inlined/icon-upgrade", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.5 2.833a5.667 5.667 0 100 11.334 5.667 5.667 0 000-11.334zM1.417 8.5a7.083 7.083 0 1114.166 0 7.083 7.083 0 01-14.166 0zm6.375-1.832L6.168 8.293A.708.708 0 015.166 7.29l2.833-2.834a.708.708 0 011.002 0l2.833 2.834a.708.708 0 01-1.002 1.002L9.208 6.668v5.374a.708.708 0 11-1.416 0V6.668z\" fill=\"#451CCB\"/>",
    "attrs": {
      "width": "17",
      "height": "17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});