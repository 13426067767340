define("ember-svg-jar/inlined/collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6 3l6 6-6 6M18 3l-6 6 6 6\" fill=\"none\" fill-rule=\"evenodd\" stroke=\"#7A232E\" stroke-width=\"2\"/>",
    "attrs": {
      "width": "24",
      "height": "18",
      "viewBox": "0 0 24 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});