define("ember-svg-jar/inlined/exclamation-mark-light-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(0 .5)\" fill=\"none\"><circle stroke=\"#F2F2F2\" cx=\"7\" cy=\"6.5\" r=\"6.5\"/><path d=\"M5.986 7.844V2.192h2.016v5.652H5.986zM8.17 9.668c0 .16-.03.31-.09.45s-.144.26-.252.36c-.108.1-.234.18-.378.24s-.296.09-.456.09a1.2 1.2 0 01-.45-.084 1.078 1.078 0 01-.366-.24 1.269 1.269 0 01-.252-.36 1.1 1.1 0 01-.006-.882 1.139 1.139 0 01.618-.612c.144-.06.296-.09.456-.09.16 0 .312.03.456.09.144.06.27.14.378.24a1.1 1.1 0 01.342.798z\" fill=\"#F2F2F2\"/></g>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});