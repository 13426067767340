define("ember-svg-jar/inlined/icon-clip-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M6 2.25a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75h1.5a1.5 1.5 0 011.5 1.5V15a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 013 15V3.75a1.5 1.5 0 011.5-1.5H6zm0 1.5H4.5V15h9V3.75H12v.75a.75.75 0 01-.75.75h-4.5A.75.75 0 016 4.5v-.75zM10.5 3h-3v.75h3V3zm1.28 5.095a.75.75 0 010 1.06l-3 3a.75.75 0 01-1.06 0l-1.5-1.5a.75.75 0 111.06-1.06l.97.97 2.47-2.47a.75.75 0 011.06 0z\" fill=\"#252222\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});