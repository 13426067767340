define("ember-svg-jar/inlined/catalog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M28.7 4.2c5.412 0 9.8 4.388 9.8 9.8v25.2c-5.412 0-9.8-4.388-9.8-9.8V4.2zm-25.2 0c5.412 0 9.8 4.388 9.8 9.8v25.2c-5.412 0-9.8-4.388-9.8-9.8V4.2zM16.1 14c0-5.412 4.388-9.8 9.8-9.8v25.2c0 5.412-4.388 9.8-9.8 9.8V14z\" fill=\"#382672\"/>",
    "attrs": {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});