define("ember-svg-jar/inlined/left-nav-vendors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M2.32 10.402a.482.482 0 01-.009-.67L8.131 3.4a.5.5 0 01.737 0l5.821 6.333a.482.482 0 01-.008.67c-3.5 3.463-8.862 3.463-12.362 0zm14 9.196a.482.482 0 00-.009.67l5.82 6.332a.5.5 0 00.737 0l5.821-6.333a.482.482 0 00-.008-.67c-3.5-3.463-8.862-3.463-12.362 0z\" fill=\"#AF97FF\"/><circle class=\"fill-current\" cx=\"8\" cy=\"22\" r=\"5\" fill=\"#AF97FF\"/><circle class=\"fill-current\" r=\"5\" transform=\"matrix(1 0 0 -1 22.5 8)\" fill=\"#AF97FF\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});