define("ember-svg-jar/inlined/clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\" stroke=\"#EF826A\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.917 39.833c9.343 0 16.916-7.573 16.916-16.916S32.26 6 22.917 6 6 13.574 6 22.917c0 9.343 7.574 16.916 16.917 16.916z\" fill=\"#F9EBE8\"/><path d=\"M23 16v7.25l3.625 3.625\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h46v46H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "46",
      "height": "46",
      "viewBox": "0 0 46 46",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});