define("ember-svg-jar/inlined/icon-profile-candid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.358.105l.61.532a.374.374 0 01.027.562L1.558 7.652l-.005.004a.43.43 0 01-.292.125.434.434 0 01-.309-.111l-.609-.53-.006-.007a.377.377 0 01-.015-.55L6.762.126a.447.447 0 01.596-.02z\" fill=\"#2A1D52\"/><path d=\"M7.352.1l.006.005A.012.012 0 017.353.1zM.335 7.133l.007.006-.007-.006zm10.716-3.807l.609.53a.384.384 0 01.14.28.374.374 0 01-.113.282L5.25 10.87l-.004.005a.43.43 0 01-.293.125.434.434 0 01-.308-.111l-.61-.53-.006-.007a.377.377 0 01-.014-.55l6.441-6.457a.447.447 0 01.588-.026c.004.002.005.005.007.007z\" fill=\"#2A1D52\"/><path d=\"M11.044 3.32l.007.006c-.002-.002-.003-.005-.007-.006zM4.03 10.352c0 .002.004.005.006.006l-.007-.006zM3.865 7.12l.616.536c.17.149.18.397.02.556l-1.71 1.714a.44.44 0 01-.594.02l-.615-.537a.375.375 0 01-.02-.556L3.27 7.14a.44.44 0 01.594-.02zM3.862.149l.617.538a.376.376 0 01.02.555l.001.002L1.347 4.4h-.001a.442.442 0 01-.595.02l-.617-.54a.375.375 0 01-.02-.555c.006-.008.014-.014.02-.02L3.248.19l.02-.02a.44.44 0 01.594-.02zM11.25 6.59l.617.537a.376.376 0 01.02.556l.001.002-3.15 3.157-.002-.002a.442.442 0 01-.595.02l-.617-.538a.375.375 0 01-.02-.555c.006-.008.014-.014.02-.02l3.11-3.118.02-.021a.444.444 0 01.596-.018z\" fill=\"#2A1D52\"/>",
    "attrs": {
      "width": "12",
      "height": "14",
      "viewBox": "0 0 12 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});