define("ember-svg-jar/inlined/icon-copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M2 4a2 2 0 012-2h10a2 2 0 012 2v4h4a2 2 0 012 2v10a2 2 0 01-2 2H10a2 2 0 01-2-2v-4H4a2 2 0 01-2-2V4zm8 12v4h10V10h-4v4a2 2 0 01-2 2h-4zm4-2V4H4v10h10z\" fill=\"#252222\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});