define("ember-svg-jar/inlined/icon-back-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M7.83.793a1 1 0 010 1.414L2.537 7.5l5.293 5.293a1 1 0 11-1.414 1.414l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 0z\" fill=\"#0D0D0D\"/>",
    "attrs": {
      "width": "9",
      "height": "15",
      "viewBox": "0 0 9 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});