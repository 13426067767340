define("ember-svg-jar/inlined/icon-log-out-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 1.5A1.5 1.5 0 011.5 0h6.75a1.5 1.5 0 011.5 1.5V3a.75.75 0 01-1.5 0V1.5H1.5v9h6.75V9a.75.75 0 011.5 0v1.5a1.5 1.5 0 01-1.5 1.5H1.5A1.5 1.5 0 010 10.5v-9zm11.47 1.72a.75.75 0 011.06 0l2.25 2.25a.75.75 0 010 1.06l-2.25 2.25a.75.75 0 11-1.06-1.06l.97-.97H5.25a.75.75 0 010-1.5h7.19l-.97-.97a.75.75 0 010-1.06z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "15",
      "height": "12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});