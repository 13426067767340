define("ember-svg-jar/inlined/plan-feature-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10 2a8 8 0 100 16 8 8 0 000-16zM0 10C0 4.477 4.477 0 10 0s10 4.477 10 10-4.477 10-10 10S0 15.523 0 10zm14.664-3.247a1 1 0 01.083 1.411l-5.333 6a1 1 0 01-1.495 0l-2.666-3a1 1 0 011.494-1.328l1.92 2.159 4.586-5.16a1 1 0 011.411-.082z\" fill=\"#FF9894\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});