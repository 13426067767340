define("ember-svg-jar/inlined/icon-collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M4.573 4.2H.71A.706.706 0 010 3.5c0-.387.318-.7.71-.7h3.863l-1.63-1.605a.692.692 0 010-.99.719.719 0 011.005 0l2.844 2.8a.695.695 0 010 .99l-2.844 2.8a.719.719 0 01-1.005 0 .692.692 0 010-.99L4.573 4.2zm11.854 0h3.862A.705.705 0 0021 3.5c0-.387-.318-.7-.71-.7h-3.863l1.63-1.605a.692.692 0 000-.99.719.719 0 00-1.005 0l-2.844 2.8a.695.695 0 000 .99l2.844 2.8a.719.719 0 001.005 0 .692.692 0 000-.99L16.427 4.2zM12 3.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z\" fill=\"#382672\"/>",
    "attrs": {
      "width": "25",
      "height": "9",
      "viewBox": "0 0 21 7",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});