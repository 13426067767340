define("ember-svg-jar/inlined/icon-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2 6a2 2 0 012-2h16a2 2 0 012 2v11a2 2 0 01-2 2h-4.586l-2.707 2.707a1 1 0 01-1.414 0L8.586 19H4a2 2 0 01-2-2V6zm18 0H4v11h5a1 1 0 01.707.293L12 19.586l2.293-2.293A1 1 0 0115 17h5V6zM6 9.5a1 1 0 011-1h10a1 1 0 110 2H7a1 1 0 01-1-1zm0 4a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1z\" fill=\"#0D0D0D\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});