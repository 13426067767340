define("ember-svg-jar/inlined/icon-contact-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.63 8.574a.466.466 0 01-.465-.467V6.882a.466.466 0 11.932 0v1.225a.466.466 0 01-.466.467z\" fill=\"#451CCB\" stroke=\"#451CCB\" stroke-width=\".25\"/><path d=\"M7.932 10.001a.699.699 0 111.398 0 .699.699 0 01-1.398 0z\" fill=\"#451CCB\"/><path d=\"M8.812 4.933c-.445-.021-.82.228-.886.63a.466.466 0 01-.92-.153c.166-.995 1.074-1.445 1.85-1.408.399.02.808.162 1.123.456.323.302.515.734.515 1.262 0 .601-.236 1.047-.644 1.32-.374.249-.833.31-1.22.31a.466.466 0 110-.932c.313 0 .553-.054.703-.154.117-.078.23-.214.23-.544 0-.288-.1-.468-.22-.58a.831.831 0 00-.53-.207z\" fill=\"#451CCB\" stroke=\"#451CCB\" stroke-width=\".25\"/><path d=\"M8.527 13.5v-.75h-.75c-.931 0-2.204-.457-3.247-1.374-1.024-.901-1.78-2.205-1.78-3.876 0-3.188 2.489-5.75 5.645-5.75a5.754 5.754 0 015.75 5.75c0 3.744-2.58 6.75-5.618 7.807V13.5z\" stroke=\"#451CCB\" stroke-width=\"1.5\"/>",
    "attrs": {
      "width": "17",
      "height": "17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});