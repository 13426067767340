define("ember-svg-jar/inlined/icon-move", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M4.293 17.707a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 16H19a1 1 0 110 2H7.414l2.293 2.293a1 1 0 01-1.414 1.414l-4-4zM16.586 8H5a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L16.586 8z\" fill=\"#252222\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});