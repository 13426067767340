define("ember-svg-jar/inlined/question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(0 1)\" fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#ffffff85\" stroke=\"#D2DDDF\" fill-rule=\"nonzero\" cx=\"7\" cy=\"6.5\" r=\"6.5\"/><path d=\"M9.37 5c0 .22-.027.413-.08.58-.053.167-.128.32-.225.46s-.213.272-.35.395a7.736 7.736 0 01-.465.385 4.563 4.563 0 00-.265.23.91.91 0 00-.165.205.772.772 0 00-.085.22 1.312 1.312 0 00-.025.275v.26H6.15v-.38c0-.173.017-.328.05-.465a1.38 1.38 0 01.15-.375 2.01 2.01 0 01.245-.33c.097-.107.212-.217.345-.33l.33-.31c.113-.1.21-.205.29-.315.08-.11.12-.242.12-.395a.68.68 0 00-.195-.51c-.13-.127-.308-.19-.535-.19-.28 0-.49.095-.63.285-.14.19-.217.405-.23.645L4.5 5.17c.04-.36.132-.677.275-.95a2.1 2.1 0 01.555-.68c.227-.18.483-.315.77-.405.287-.09.59-.135.91-.135.3 0 .592.038.875.115.283.077.535.197.755.36.22.163.397.372.53.625s.2.553.2.9zM7.91 9.42a.91.91 0 01-.28.675.946.946 0 01-.69.275 1 1 0 01-.375-.07.953.953 0 01-.31-.2 1.01 1.01 0 01-.215-.3.917.917 0 01-.005-.735.924.924 0 01.21-.305 1.008 1.008 0 01.695-.28c.133 0 .258.025.375.075.117.05.22.117.31.2s.16.183.21.3c.05.117.075.238.075.365z\" fill=\"#9BB1B6\"/></g>",
    "attrs": {
      "width": "14",
      "height": "15",
      "viewBox": "0 0 14 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});