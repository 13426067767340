define("ember-svg-jar/inlined/flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.333 31.25s2.084-2.084 8.334-2.084 10.416 4.167 16.666 4.167 8.334-2.083 8.334-2.083v-25s-2.084 2.083-8.334 2.083-10.416-4.167-16.666-4.167S8.333 6.25 8.333 6.25v25z\" fill=\"#F9EBE8\" stroke=\"#EF826A\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M8.333 45.833V31.25\" stroke=\"#EF826A\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});