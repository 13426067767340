define("ember-svg-jar/inlined/state-line-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M107.001 1.5H0\" stroke=\"#4B8FDE\" stroke-width=\"2\" fill=\"none\"/>",
    "attrs": {
      "width": "107",
      "height": "3",
      "viewBox": "0 0 107 3",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});