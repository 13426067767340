define("ember-svg-jar/inlined/down-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect x=\"49.5\" y=\".5\" width=\"49\" height=\"49\" rx=\"24.5\" transform=\"rotate(90 49.5 .5)\" stroke=\"#C4D0DD\"/><path d=\"M20 24l5 5 5-5\" stroke=\"#ACACAC\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});