define("ember-svg-jar/inlined/arrow-right-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.926 4.418a.795.795 0 01.576-.251c.216 0 .423.09.576.251l4.89 5.148c.153.16.239.379.239.606a.881.881 0 01-.239.607l-4.89 5.147a.794.794 0 01-.573.24.795.795 0 01-.57-.25.881.881 0 01-.238-.6.882.882 0 01.229-.603l3.498-3.683H3.982a.795.795 0 01-.577-.251.881.881 0 01-.238-.607c0-.227.085-.446.238-.607a.795.795 0 01.577-.25h9.442L9.926 5.63a.881.881 0 01-.24-.606c0-.228.087-.446.24-.607z\" fill=\"#382672\" class=\"fill-current\"/>",
    "attrs": {
      "width": "19",
      "height": "20",
      "class": "fill-current",
      "viewBox": "0 0 19 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});