define("ember-svg-jar/inlined/iso-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M34.628 16.993L6.116.103a.727.727 0 00-.744 0A.767.767 0 005 .762v11.67l11.321 8.028 1.133 14.106 5.72 3.388 1.578 8.426a.75.75 0 00.711.619c.36 0 .66-.238.742-.58l1.409-5.835 6.27 3.715a.73.73 0 00.744 0 .767.767 0 00.372-.661V17.654a.767.767 0 00-.372-.661z\" fill=\"#D1E2F4\"/><path d=\"M34.621 16.993L5.6.103a.752.752 0 00-.758 0 .764.764 0 00-.378.66v11.67l-3.327-1.935a.752.752 0 00-.757 0 .764.764 0 00-.379.66v15.59c0 .272.144.524.379.66l7.162 4.17 1.411 5.749a.759.759 0 00.735.58c.322 0 .61-.205.716-.514l1.188-3.458 4.413 2.57a.752.752 0 00.757 0 .764.764 0 00.38-.662v-1.277l5.82 3.389 1.608 8.426a.76.76 0 00.743.619.757.757 0 00.735-.58l1.434-5.835 6.383 3.714a.752.752 0 00.757 0 .764.764 0 00.379-.66V17.653a.764.764 0 00-.379-.661zM15.626 34.52l-4.072-2.37a.752.752 0 00-1.094.411l-.652 1.897-.874-3.563a.763.763 0 00-.357-.478l-7.063-4.111V12.48l14.112 8.212V34.52zm17.86 7.795l-6.12-3.56a.752.752 0 00-.674-.041.762.762 0 00-.439.518l-.848 3.45-1.021-5.359a.763.763 0 00-.365-.517l-6.88-4.003.001-12.552a.765.765 0 00-.379-.66L5.977 13.314V2.085l27.509 16.01v24.221z\" fill=\"#4B8FDE\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "35",
      "height": "47",
      "viewBox": "0 0 35 47",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});