define("ember-svg-jar/inlined/icon-log-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M1.5 4.5A1.5 1.5 0 013 3h6.75a1.5 1.5 0 011.5 1.5V6a.75.75 0 01-1.5 0V4.5H3v9h6.75V12a.75.75 0 011.5 0v1.5a1.5 1.5 0 01-1.5 1.5H3a1.5 1.5 0 01-1.5-1.5v-9zm11.47 1.72a.75.75 0 011.06 0l2.25 2.25a.75.75 0 010 1.06l-2.25 2.25a.75.75 0 11-1.06-1.06l.97-.97H6.75a.75.75 0 010-1.5h7.19l-.97-.97a.75.75 0 010-1.06z\" fill=\"#451CCB\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});