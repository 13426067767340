define("liquid-fire/components/liquid-outlet", ["exports", "@ember/object", "@ember/component", "liquid-fire/templates/components/liquid-outlet", "liquid-fire/ember-internals"], function (_exports, _object, _component, _liquidOutlet, _emberInternals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LiquidOutlet = _component.default.extend({
    layout: _liquidOutlet.default,
    positionalParams: ['inputOutletName'],
    // needed for Ember 1.13.[0-5] and 2.0.0-beta.[1-3] support
    tagName: '',
    versionEquality: (0, _object.computed)('outletName', 'watchModels', function () {
      var outletName = this.outletName;
      var watchModels = this.watchModels;
      return function (oldValue, newValue) {
        var oldChild = (0, _emberInternals.childRoute)(oldValue, outletName);
        var newChild = (0, _emberInternals.childRoute)(newValue, outletName);
        return (0, _emberInternals.routeIsStable)(oldChild, newChild) && (!watchModels || (0, _emberInternals.modelIsStable)(oldChild, newChild));
      };
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('outletName', this.inputOutletName || 'main');
    }
  });
  LiquidOutlet.reopenClass({
    positionalParams: ['inputOutletName']
  });
  var _default = LiquidOutlet;
  _exports.default = _default;
});