define("ember-in-viewport/utils/is-in-viewport", ["exports", "@ember/polyfills"], function (_exports, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isInViewport;
  var defaultTolerance = {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  };
  function isInViewport() {
    var boundingClientRect = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var height = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    var width = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
    var tolerance = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : defaultTolerance;
    var top = boundingClientRect.top,
      left = boundingClientRect.left,
      bottom = boundingClientRect.bottom,
      right = boundingClientRect.right,
      h = boundingClientRect.height,
      w = boundingClientRect.width;
    var tolerances = (0, _polyfills.assign)((0, _polyfills.assign)({}, defaultTolerance), tolerance);
    var topTolerance = tolerances.top,
      leftTolerance = tolerances.left,
      bottomTolerance = tolerances.bottom,
      rightTolerance = tolerances.right;
    return top + topTolerance >= 0 && left + leftTolerance >= 0 && Math.round(bottom) - bottomTolerance - h <= Math.round(height) && Math.round(right) - rightTolerance - w <= Math.round(width);
  }
});