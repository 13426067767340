define("ember-svg-jar/inlined/dicovery-profile-empty-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"76\" cy=\"76\" r=\"76\" fill=\"url(#a)\"/><path d=\"M76 0C34.094 0 0 34.094 0 76s34.094 76 76 76 76-34.094 76-76S117.906 0 76 0zm.73 35.077a26.307 26.307 0 110 52.615 26.307 26.307 0 010-52.615zM76 140.308a64.153 64.153 0 01-47.2-20.681C32.03 102.9 61.04 99.385 76 99.385c14.959 0 43.97 3.515 47.2 20.238A64.134 64.134 0 0176 140.308z\" fill=\"url(#b)\"/><defs><linearGradient id=\"a\" x1=\"-13.793\" y1=\"3.659\" x2=\"187.745\" y2=\"91.812\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#EF826A\"/><stop offset=\".297\" stop-color=\"#CA9387\"/><stop offset=\".49\" stop-color=\"#B59D98\"/><stop offset=\".698\" stop-color=\"#A3A5A6\"/><stop offset=\"1\" stop-color=\"#7EB6C4\"/><stop offset=\"1\" stop-color=\"#7EB6C4\"/><stop offset=\"1\" stop-color=\"#7EB6C4\"/><stop offset=\"1\" stop-color=\"#7EB6C4\"/></linearGradient><linearGradient id=\"b\" x1=\"-30.785\" y1=\"-69.94\" x2=\"289.215\" y2=\"72.811\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#fff\" stop-opacity=\".24\"/><stop offset=\"1\" stop-color=\"#fff\" stop-opacity=\".56\"/></linearGradient></defs>",
    "attrs": {
      "width": "152",
      "height": "152",
      "viewBox": "0 0 152 152",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});