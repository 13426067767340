define("ember-uploader/uploaders/s3", ["exports", "jquery", "rsvp", "@ember/object", "@ember/runloop", "ember-uploader/uploaders/uploader", "@ember/polyfills"], function (_exports, _jquery, _rsvp, _object, _runloop, _uploader, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _uploader.default.extend({
    /**
     * Target url used to request a signed upload policy
     *
     * @property url
     */
    signingUrl: '/sign',
    /**
     * request method for signing
     *
     * @property method
     */
    signingMethod: 'GET',
    /**
     * Boolean property changed to true upon signing start and false upon
     * signing end
     *
     * @property isSigning
     */
    isSigning: false,
    /**
     * Request signed upload policy and upload file(s) and any extra data
     *
     * @param  {object} file  A file object
     * @param  {object} extra Extra data to be sent with the upload
     * @return {object} Returns a Ember.RSVP.Promise wrapping the signing
     * request object
     */
    upload: function upload(file) {
      var _this = this;
      var extra = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.sign(file, extra).then(function (json) {
        var url;
        (0, _object.set)(_this, 'isUploading', true);
        if (json.endpoint) {
          url = json.endpoint;
          delete json.endpoint;
        } else if (json.region) {
          url = "https://s3-".concat(json.region, ".amazonaws.com/").concat(json.bucket);
          delete json.region;
        } else {
          url = "https://".concat(json.bucket, ".s3.amazonaws.com");
        }
        return _this.ajax(url, _this.createFormData(file, json));
      });
    },
    /**
     * Request signed upload policy
     *
     * @param  {object} file  A file object
     * @param  {object} extra Extra data to be sent with the upload
     * @return {object} Returns a Ember.RSVP.Promise wrapping the signing
     * request object
     */
    sign: function sign(file) {
      var _this2 = this;
      var extra = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var url = (0, _object.get)(this, 'signingUrl');
      var method = (0, _object.get)(this, 'signingMethod');
      var signingAjaxSettings = (0, _object.get)(this, 'signingAjaxSettings');
      extra.name = file.name;
      extra.type = file.type;
      extra.size = file.size;
      var settings = (0, _polyfills.assign)({}, {
        contentType: 'application/json',
        dataType: 'json',
        data: method.match(/get/i) ? extra : JSON.stringify(extra),
        method: method,
        url: url
      }, signingAjaxSettings);
      (0, _object.set)(this, 'isSigning', true);
      return new _rsvp.Promise(function (resolve, reject) {
        settings.success = function (json) {
          (0, _runloop.run)(null, resolve, _this2.didSign(json));
        };
        settings.error = function (jqXHR, responseText, errorThrown) {
          (0, _runloop.run)(null, reject, _this2.didErrorOnSign(jqXHR, responseText, errorThrown));
        };
        _jquery.default.ajax(settings);
      });
    },
    /**
     * Triggers didErrorOnSign event and sets isSigning to false
     *
     * @param {object} jqXHR jQuery XMLHttpRequest object
     * @param {string} textStatus The status code of the error
     * @param {object} errorThrown The error caused
     * @return {object} Returns the jQuery XMLHttpRequest
     */
    didErrorOnSign: function didErrorOnSign(jqXHR, textStatus, errorThrown) {
      (0, _object.set)(this, 'isSigning', false);
      this.trigger('didErrorOnSign');
      this.didError(jqXHR, textStatus, errorThrown);
      return jqXHR;
    },
    /**
     * Triggers didSign event and returns the signing response
     *
     * @param {object} response The signing response
     * @return {object} The signing response
     */
    didSign: function didSign(response) {
      this.trigger('didSign', response);
      return response;
    }
  });
  _exports.default = _default;
});