define("ember-svg-jar/inlined/buyer-seller-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.429 24l-9.981-9.895a2 2 0 01.015-2.855l.77-.748a2 2 0 012.794.008l6.402 6.28L26.956 1.397a2 2 0 012.816 0l.795.788a2 2 0 010 2.841L11.43 24z\" fill=\"#7A58E8\"/>",
    "attrs": {
      "width": "32",
      "height": "24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});