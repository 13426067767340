define("ember-svg-jar/inlined/icon-order-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.75 1.75a1.5 1.5 0 00-1.5-1.5H1.75a1.5 1.5 0 00-1.5 1.5v13.5a1.5 1.5 0 001.5 1.5h16.5a1.5 1.5 0 001.5-1.5V1.75zm-18 13.5V4H9v11.25H1.75zm8.75 0h7.75V4H10.5v11.25z\" fill=\"#0D0D0D\"/>",
    "attrs": {
      "width": "20",
      "height": "17",
      "viewBox": "0 0 20 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});