define("ember-svg-jar/inlined/blue-invoice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4 43V7a2 2 0 012-2h28a2 2 0 012 2v32l-6 6H6a2 2 0 01-2-2z\" fill=\"#EDF5F9\"/><path d=\"M36 39l-6 6m6-6h-5a1 1 0 00-1 1v5m6-6V7a2 2 0 00-2-2H6a2 2 0 00-2 2v36a2 2 0 002 2h24\" stroke=\"#7EB6C4\"/><path d=\"M20.5 23.344c-2.165 0-3.979-1.457-3.979-3.302 0-1.846 1.756-3.303 3.979-3.303s3.979 1.457 3.979 3.303H26c0-2.283-2.048-4.225-4.74-4.517V13h-1.52v2.574c-2.692.291-4.74 2.234-4.74 4.516 0 2.526 2.457 4.566 5.5 4.566 2.165 0 3.979 1.457 3.979 3.302 0 1.846-1.756 3.303-3.979 3.303s-3.979-1.457-3.979-3.303H15c0 2.283 2.048 4.225 4.74 4.517V35h1.52v-2.525c2.692-.292 4.74-2.234 4.74-4.517 0-2.574-2.457-4.614-5.5-4.614z\" fill=\"#7EB6C4\"/>",
    "attrs": {
      "width": "40",
      "height": "50",
      "viewBox": "0 0 40 50",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});