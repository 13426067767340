define("ember-svg-jar/inlined/remove-circle-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(.75 .125)\" fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#FFF\" fill-rule=\"nonzero\" cx=\"8.5\" cy=\"8.5\" r=\"8.5\"/><path d=\"M4.964 12.036l7.072-7.072m0 7.072L4.964 4.964\" stroke=\"#A19182\"/></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});