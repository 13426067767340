define("ember-svg-jar/inlined/large-chevron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"large-chevron\" d=\"M2 2l9 8.504L2 19\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "14",
      "height": "21",
      "viewBox": "0 0 14 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});