define("ember-svg-jar/inlined/icon-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.285 3.379c.31.31.465.686.465 1.129V15.53c0 .443-.155.82-.465 1.13a1.54 1.54 0 01-1.129.464H1.594c-.443 0-.82-.155-1.13-.465A1.537 1.537 0 010 15.531V1.72C0 1.277.155.9.465.59.775.28 1.15.126 1.594.126h6.773c.443 0 .82.155 1.13.465l2.788 2.789zm-1.262.996L8.5 1.852v2.523h2.523zm-9.43 11.156h9.563V5.97H7.703a.768.768 0 01-.564-.233.768.768 0 01-.233-.564V1.719H1.594V15.53zm1.063-1.593v-2.125l1.295-1.295a.413.413 0 01.299-.133c.11 0 .21.033.299.1l1.295 1.328L8.766 8.89a.359.359 0 01.265-.1c.11 0 .21.033.3.1l.763.797v4.25H2.656zm1.594-7.97c.443 0 .819.156 1.129.466.31.31.465.686.465 1.129 0 .442-.155.819-.465 1.128-.31.31-.686.465-1.129.465-.443 0-.819-.155-1.129-.465a1.537 1.537 0 01-.465-1.128c0-.443.155-.82.465-1.13.31-.31.686-.464 1.129-.464z\" fill=\"#B3C5C8\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "13",
      "height": "18",
      "viewBox": "0 0 13 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});