define("ember-svg-jar/inlined/icon-customer-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"stroke-current\" d=\"M1.1 31.813A6.902 6.902 0 006.9 25v-7.813A6.902 6.902 0 001.1 24v7.813zm16.8-6A6.902 6.902 0 0112.1 19v-7.813A6.902 6.902 0 0117.9 18v7.813z\" stroke=\"#000\" stroke-width=\"2.2\"/><circle class=\"stroke-current\" cx=\"4.5\" cy=\"10\" r=\"2.9\" stroke=\"#000\" stroke-width=\"2.2\"/><circle class=\"stroke-current\" cx=\"14.5\" cy=\"4\" r=\"2.9\" transform=\"rotate(180 14.5 4)\" stroke=\"#000\" stroke-width=\"2.2\"/>",
    "attrs": {
      "width": "19",
      "height": "33",
      "viewBox": "0 0 19 33",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});