define("ember-svg-jar/inlined/share-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle class=\"stroke-current\" cx=\"14.5\" cy=\"6\" r=\"1.75\" stroke-width=\"1.5\"/><circle class=\"stroke-current\" cx=\"14.5\" cy=\"15\" r=\"1.75\" stroke-width=\"1.5\"/><circle class=\"stroke-current\" cx=\"5.5\" cy=\"10.5\" r=\"1.75\" stroke-width=\"1.5\"/><path class=\"stroke-current\" d=\"M7.5 9.5l5-2.5m-5 5l5.079 1.81\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "20",
      "height": "21",
      "stroke": "#451CCB",
      "viewBox": "0 0 20 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});