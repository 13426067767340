define("ember-svg-jar/inlined/left-nav-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M24.529.383l2.03 1.933c.273.252.455.612.465 1.016a1.43 1.43 0 01-.374 1.027L5.193 27.824l-.016.016a1.375 1.375 0 01-.975.454 1.378 1.378 0 01-1.028-.404l-2.03-1.928a1.422 1.422 0 01-.445-.994 1.43 1.43 0 01.375-1.027L22.541.454c.247-.268.59-.442.975-.453a1.412 1.412 0 011.013.382z\" fill=\"#FFFBF9\"/><path d=\"M24.507.361l.021.022a.043.043 0 01-.021-.022zM1.117 25.94l.022.021-.022-.021zm35.719-13.846l2.03 1.928c.273.251.455.612.466 1.016a1.43 1.43 0 01-.375 1.027L17.501 39.529l-.016.017c-.247.267-.59.442-.975.453a1.378 1.378 0 01-1.028-.404l-2.03-1.928a1.422 1.422 0 01-.444-.994 1.43 1.43 0 01.374-1.027l21.472-23.481a1.382 1.382 0 011.961-.093c.01.006.016.016.021.022z\" fill=\"#FFFBF9\"/><path d=\"M36.815 12.072l.021.022c-.005-.005-.01-.016-.021-.022zM13.43 37.645l.022.021-.021-.021zm-.545-11.755l2.05 1.95c.569.54.6 1.442.07 2.021l-5.698 6.232a1.38 1.38 0 01-1.982.071l-2.051-1.95a1.447 1.447 0 01-.07-2.02l5.699-6.233a1.38 1.38 0 011.982-.07zM12.873.542l2.057 1.955a1.45 1.45 0 01.065 2.02l.005.006L4.49 16.005l-.005-.006a1.385 1.385 0 01-1.982.071L.447 14.115a1.447 1.447 0 01-.07-2.021c.022-.028.049-.05.07-.071L10.827.689l.065-.076a1.38 1.38 0 011.982-.071zm24.628 23.42l2.057 1.956a1.45 1.45 0 01.064 2.02l.005.006-10.503 11.481-.005-.005a1.384 1.384 0 01-1.982.07l-2.057-1.955a1.447 1.447 0 01-.07-2.02c.022-.028.049-.05.07-.072l10.37-11.339c.021-.027.042-.05.064-.076a1.391 1.391 0 011.987-.066z\" fill=\"#FFFBF9\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});