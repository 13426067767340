define("ember-svg-jar/inlined/icon-cloud-synced", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.4 4.8a4.8 4.8 0 019.337-1.57 4.8 4.8 0 01-.537 9.57H4a4 4 0 01-1.589-7.672A4.858 4.858 0 012.4 4.8zm4.8-3.2a3.2 3.2 0 00-3.123 3.903.8.8 0 01-.605.955A2.401 2.401 0 004 11.2h7.2a3.2 3.2 0 10-.064-6.4.8.8 0 01-.8-.64A3.201 3.201 0 007.2 1.6z\" fill=\"#0D0D0D\"/><path d=\"M7.2 1.6a3.2 3.2 0 00-3.123 3.903.8.8 0 01-.605.955A2.401 2.401 0 004 11.2h7.2a3.2 3.2 0 10-.064-6.4.8.8 0 01-.8-.64A3.201 3.201 0 007.2 1.6z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "16",
      "height": "13",
      "viewBox": "0 0 16 13",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});