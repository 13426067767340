define("ember-svg-jar/inlined/left-nav-storefront", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M15.5 7v16H.5v-1c0-8.284 6.716-15 15-15zm3 16V7h15v1c0 8.284-6.716 15-15 15z\" fill=\"#AF97FF\"/>",
    "attrs": {
      "width": "34",
      "height": "30",
      "viewBox": "0 0 34 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});