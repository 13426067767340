define("ember-in-viewport/mixins/in-viewport", ["exports", "@ember/polyfills", "@ember/object/mixin", "@ember/utils", "@ember/debug", "@ember/service", "@ember/object", "@ember/runloop", "@ember/object/computed", "@ember/application", "ember-in-viewport/-private/raf-admin", "ember-in-viewport/utils/can-use-dom", "ember-in-viewport/utils/can-use-raf", "ember-in-viewport/utils/find-elem", "ember-in-viewport/utils/can-use-intersection-observer", "ember-in-viewport/utils/check-scroll-direction"], function (_exports, _polyfills, _mixin, _utils, _debug, _service, _object, _runloop, _computed, _application, _rafAdmin, _canUseDom, _canUseRaf, _findElem, _canUseIntersectionObserver, _checkScrollDirection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins, ember/no-get, ember/no-observers, ember/no-incorrect-calls-with-inline-anonymous-functions */

  var lastDirection = {};
  var lastPosition = {};
  var _default = _mixin.default.create({
    /**
     * @property _debouncedEventHandler
     * @default null
     */
    _debouncedEventHandler: null,
    /**
     * unbinding listeners will short circuit rAF
     *
     * @property _stopListening
     * @default false
     */
    _stopListening: false,
    inViewport: (0, _service.inject)(),
    /**
     * @property viewportExited
     * @type Boolean
     */
    viewportExited: (0, _computed.not)('viewportEntered').readOnly(),
    init: function init() {
      // ensure this mixin runs first, then your component can override the options
      this._super.apply(this, arguments);
      var options = (0, _polyfills.assign)({
        viewportUseRAF: (0, _canUseRaf.default)(),
        viewportEntered: false,
        viewportListeners: []
      }, this._buildOptions());

      // set viewportUseIntersectionObserver after merging users config to avoid errors in browsers that lack support (https://github.com/DockYard/ember-in-viewport/issues/146)
      options = (0, _polyfills.assign)(options, {
        viewportUseIntersectionObserver: (0, _canUseIntersectionObserver.default)()
      });
      (0, _object.setProperties)(this, options);
      (0, _object.set)(this, '_evtListenerClosures', []);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      (false && !(false) && (0, _debug.deprecate)('This mixin is deprecated. We suggest you migrate to the inViewport service or use the {{in-viewport}} modifier', false, {
        id: 'ember-in-viewport.mixin',
        until: '4.0.0',
        url: 'https://github.com/DockYard/ember-in-viewport#readme'
      }));
      if (!_canUseDom.default) {
        return;
      }
      var viewportEnabled = (0, _object.get)(this, 'viewportEnabled');
      if (viewportEnabled) {
        this.watchElement((0, _object.get)(this, 'element'));
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._unbindListeners((0, _object.get)(this, 'element'));
    },
    _buildOptions: function _buildOptions() {
      var defaultOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var owner = (0, _application.getOwner)(this);
      if (owner) {
        return (0, _polyfills.assign)(defaultOptions, owner.lookup('config:in-viewport'));
      }
    },
    watchElement: function watchElement(element) {
      var _this = this;
      this._setInitialViewport(element);
      this._addObserverIfNotSpying(element);
      var viewportDidScroll = (0, _object.get)(this, 'viewportDidScroll');
      if (viewportDidScroll) {
        (0, _debug.debug)('[viewportDidScroll] This will be false by default in the next major release');
        this._bindScrollDirectionListener((0, _object.get)(this, 'viewportScrollSensitivity'));
      }
      if (!(0, _object.get)(this, 'viewportUseIntersectionObserver') && !(0, _object.get)(this, 'viewportUseRAF')) {
        (0, _object.get)(this, 'viewportListeners').forEach(function (listener) {
          var context = listener.context,
            event = listener.event;
          context = (0, _object.get)(_this, 'scrollableArea') || context;
          _this._bindListeners(context, event, element);
        });
      }
    },
    _addObserverIfNotSpying: function _addObserverIfNotSpying(element) {
      if (!(0, _object.get)(this, 'viewportSpy')) {
        this.addObserver('viewportEntered', this, (0, _runloop.bind)(this, '_unbindIfEntered', element));
      }
    },
    _setInitialViewport: function _setInitialViewport(element) {
      var _this2 = this;
      var isTearingDown = this.isDestroyed || this.isDestroying;
      if (!element || isTearingDown) {
        return;
      }
      var inViewport = (0, _object.get)(this, 'inViewport');
      if ((0, _object.get)(this, 'viewportUseIntersectionObserver')) {
        return (0, _runloop.scheduleOnce)('afterRender', this, function () {
          var scrollableArea = (0, _object.get)(_this2, 'scrollableArea');
          var viewportTolerance = (0, _object.get)(_this2, 'viewportTolerance');
          var intersectionThreshold = (0, _object.get)(_this2, 'intersectionThreshold');
          inViewport.watchElement(element, {
            intersectionThreshold: intersectionThreshold,
            viewportTolerance: viewportTolerance,
            scrollableArea: scrollableArea
          }, (0, _runloop.bind)(_this2, _this2._onEnterIntersection), (0, _runloop.bind)(_this2, _this2._onExitIntersection));
        });
      } else if ((0, _object.get)(this, 'viewportUseRAF')) {
        inViewport.startRAF();
        var scrollableArea = (0, _object.get)(this, 'scrollableArea');
        var viewportTolerance = (0, _object.get)(this, 'viewportTolerance');
        var viewportSpy = (0, _object.get)(this, 'viewportSpy');
        var enterCallback = function enterCallback() {
          var isTearingDown = _this2.isDestroyed || _this2.isDestroying;
          var viewportEntered = element.getAttribute('data-in-viewport-entered') === 'true';
          if (!isTearingDown && (viewportSpy || viewportEntered)) {
            (0, _object.set)(_this2, 'viewportEntered', true);
            _this2.trigger('didEnterViewport');
          }
        };
        var exitCallback = function exitCallback() {
          var isTearingDown = _this2.isDestroyed || _this2.isDestroying;
          if (!isTearingDown && viewportSpy) {
            (0, _object.set)(_this2, 'viewportEntered', false);
            _this2.trigger('didExitViewport');
          }
        };
        (0, _rafAdmin.startRAF)(element, {
          scrollableArea: scrollableArea,
          viewportTolerance: viewportTolerance,
          viewportSpy: viewportSpy
        }, enterCallback, exitCallback, inViewport.addRAF.bind(inViewport, element.id), inViewport.removeRAF.bind(inViewport, element.id));
      } else {
        return (0, _runloop.scheduleOnce)('afterRender', this, function () {
          _this2._setViewportEntered(element);
        });
      }
    },
    /**
     * used by rAF and scroll event listeners to determine if mixin is in viewport
     * Remember to set `viewportSpy` to true if you want to continuously observe your element
     *
     * @method _setViewportEntered
     */
    _setViewportEntered: function _setViewportEntered(element) {
      var scrollableArea = (0, _object.get)(this, 'scrollableArea') ? document.querySelector((0, _object.get)(this, 'scrollableArea')) : undefined;
      var height = scrollableArea ? scrollableArea.offsetHeight + scrollableArea.getBoundingClientRect().top : window.innerHeight;
      var width = scrollableArea ? scrollableArea.offsetWidth + scrollableArea.getBoundingClientRect().left : window.innerWidth;
      var boundingClientRect = element.getBoundingClientRect();
      if (boundingClientRect) {
        this._triggerDidAccessViewport((0, _object.get)(this, 'inViewport').isInViewport(boundingClientRect, height, width, (0, _object.get)(this, 'viewportTolerance')), (0, _object.get)(this, 'viewportEntered'));
        if ((0, _object.get)(this, 'viewportUseRAF') && !(0, _object.get)(this, '_stopListening')) {
          (0, _object.get)(this, 'inViewport').addRAF((0, _object.get)(this, 'elementId'), (0, _runloop.bind)(this, this._setViewportEntered, element));
        }
      }
    },
    /**
     * Callback provided to IntersectionObserver
     * trigger didEnterViewport callback
     *
     * @method _onEnterIntersection
     */
    _onEnterIntersection: function _onEnterIntersection() {
      var isTearingDown = this.isDestroyed || this.isDestroying;
      if (!isTearingDown) {
        (0, _object.set)(this, 'viewportEntered', true);
      }
      this.trigger('didEnterViewport');
    },
    /**
     * trigger didExitViewport callback
     *
     * @method _onExitIntersection
     */
    _onExitIntersection: function _onExitIntersection() {
      var isTearingDown = this.isDestroyed || this.isDestroying;
      if (!isTearingDown) {
        (0, _object.set)(this, 'viewportEntered', false);
      }
      this.trigger('didExitViewport');
    },
    /**
     * @method _triggerDidScrollDirection
     * @param contextEl
     * @param sensitivity
     */
    _triggerDidScrollDirection: function _triggerDidScrollDirection() {
      var contextEl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var sensitivity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      (false && !(contextEl) && (0, _debug.assert)('You must pass a valid context element to _triggerDidScrollDirection', contextEl));
      (false && !(sensitivity) && (0, _debug.assert)('sensitivity cannot be 0', sensitivity));
      var elementId = (0, _object.get)(this, 'elementId');
      var lastDirectionForEl = lastDirection[elementId];
      var lastPositionForEl = lastPosition[elementId];
      var newPosition = {
        top: contextEl.scrollTop,
        left: contextEl.scrollLeft
      };
      var scrollDirection = (0, _checkScrollDirection.default)(lastPositionForEl, newPosition, sensitivity);
      var directionChanged = scrollDirection !== lastDirectionForEl;
      if (scrollDirection && directionChanged && (0, _object.get)(this, 'viewportDidScroll')) {
        this.trigger('didScroll', scrollDirection);
        lastDirection[elementId] = scrollDirection;
      }
      lastPosition[elementId] = newPosition;
    },
    /**
     * @method _triggerDidAccessViewport
     * @param hasEnteredViewport
     * @param viewportEntered
     */
    _triggerDidAccessViewport: function _triggerDidAccessViewport() {
      var hasEnteredViewport = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var viewportEntered = arguments.length > 1 ? arguments[1] : undefined;
      var isTearingDown = this.isDestroyed || this.isDestroying;
      if (isTearingDown) {
        return;
      }
      var didEnter = !viewportEntered && hasEnteredViewport;
      var didLeave = viewportEntered && !hasEnteredViewport;
      var triggeredEventName = '';
      if (didEnter) {
        triggeredEventName = 'didEnterViewport';
      }
      if (didLeave) {
        triggeredEventName = 'didExitViewport';
      }
      if ((0, _object.get)(this, 'viewportSpy') || !viewportEntered) {
        (0, _object.set)(this, 'viewportEntered', hasEnteredViewport);
      }
      if (triggeredEventName) {
        this.trigger(triggeredEventName);
      }
    },
    /**
     * Unbind when enter viewport only if viewportSpy is false
     *
     * @method _unbindIfEntered
     */
    _unbindIfEntered: function _unbindIfEntered(element) {
      if ((0, _object.get)(this, 'viewportEntered')) {
        this._unbindListeners(element);
        this.removeObserver('viewportEntered', this, '_unbindIfEntered');
        (0, _object.set)(this, 'viewportEntered', false);
      }
    },
    /**
     * General utility function
     *
     * @method _debouncedEvent
     */
    _debouncedEvent: function _debouncedEvent(methodName) {
      var _this3 = this;
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      (false && !(methodName) && (0, _debug.assert)('You must pass a methodName to _debouncedEvent', methodName));
      (false && !((0, _utils.typeOf)(methodName) === 'string') && (0, _debug.assert)('methodName must be a string', (0, _utils.typeOf)(methodName) === 'string'));
      (0, _runloop.debounce)(this, function () {
        return _this3[methodName].apply(_this3, args);
      }, (0, _object.get)(this, 'viewportRefreshRate'));
    },
    _bindScrollDirectionListener: function _bindScrollDirectionListener() {
      var sensitivity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      (false && !(sensitivity) && (0, _debug.assert)('sensitivity cannot be 0', sensitivity));
      var contextEl = (0, _object.get)(this, 'scrollableArea') || window;
      var elem = (0, _findElem.default)(contextEl);
      this._debouncedEventHandler = this._debouncedEvent.bind(this, '_triggerDidScrollDirection', elem, sensitivity);
      elem.addEventListener('scroll', this._debouncedEventHandler, {
        passive: true
      });
    },
    _unbindScrollDirectionListener: function _unbindScrollDirectionListener() {
      var elementId = (0, _object.get)(this, 'elementId');
      var context = (0, _object.get)(this, 'scrollableArea') || window;
      var elem = (0, _findElem.default)(context);
      if (elem) {
        elem.removeEventListener('scroll', this._debouncedEventHandler, {
          passive: true
        });
        delete lastPosition[elementId];
        delete lastDirection[elementId];
      }
    },
    /**
     * Only if not using IntersectionObserver and rAF
     *
     * @method _bindListeners
     */
    _bindListeners: function _bindListeners() {
      var _this4 = this;
      var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var event = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var element = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      (false && !(context) && (0, _debug.assert)('You must pass a valid context to _bindListeners', context));
      (false && !(event) && (0, _debug.assert)('You must pass a valid event to _bindListeners', event));
      var elem = (0, _findElem.default)(context);
      var evtListener = function evtListener() {
        return _this4._debouncedEvent('_setViewportEntered', element);
      };
      this._evtListenerClosures.push({
        event: event,
        evtListener: evtListener
      });
      elem.addEventListener(event, evtListener, false);
    },
    /**
     * Remove listeners for rAF or scroll event listeners
     * Either from component destroy or viewport entered and
     * need to turn off listening
     *
     * @method _unbindListeners
     */
    _unbindListeners: function _unbindListeners(element) {
      var _this5 = this;
      (0, _object.set)(this, '_stopListening', true);

      // if IntersectionObserver
      if ((0, _object.get)(this, 'viewportUseIntersectionObserver') && (0, _object.get)(this, 'viewportEnabled')) {
        (0, _object.get)(this, 'inViewport').unobserveIntersectionObserver(element);
      }

      // if rAF
      if (!(0, _object.get)(this, 'viewportUseIntersectionObserver') && (0, _object.get)(this, 'viewportUseRAF')) {
        var elementId = (0, _object.get)(this, 'elementId');
        (0, _object.get)(this, 'inViewport').removeRAF(elementId);
      }

      // if scroll event listeners
      if (!(0, _object.get)(this, 'viewportUseIntersectionObserver') && !(0, _object.get)(this, 'viewportUseRAF')) {
        (0, _object.get)(this, 'viewportListeners').forEach(function (listener) {
          var context = listener.context,
            event = listener.event;
          context = (0, _object.get)(_this5, 'scrollableArea') || context;
          var elem = (0, _findElem.default)(context);
          var _ref = _this5._evtListenerClosures.find(function (closure) {
              return event === closure.event;
            }) || {},
            evtListener = _ref.evtListener;
          elem.removeEventListener(event, evtListener, false);
        });
      }

      // 4. last but not least
      var viewportDidScroll = (0, _object.get)(this, 'viewportDidScroll');
      if (viewportDidScroll) {
        this._unbindScrollDirectionListener();
      }
    }
  });
  _exports.default = _default;
});