define("ember-svg-jar/inlined/note-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.342 0c.44 0 .82.16 1.142.482.322.322.483.703.483 1.143L14 8.375c0 .44-.16.821-.482 1.143a1.562 1.562 0 01-1.143.482H8.719l-5.207 2.95a.336.336 0 01-.19.05.28.28 0 01-.204-.089.294.294 0 01-.089-.216V10h-.437c-.44 0-.821-.16-1.143-.482a1.562 1.562 0 01-.482-1.143v-6.75c0-.44.16-.821.482-1.143A1.562 1.562 0 012.592 0h9.75zm-.375 7.594V2.406a.395.395 0 00-.115-.292A.395.395 0 0011.56 2H3.377a.395.395 0 00-.292.114.395.395 0 00-.115.292v5.188c0 .118.039.216.115.292A.395.395 0 003.377 8h1.395v2l3.533-2h3.255a.395.395 0 00.292-.114.395.395 0 00.115-.292z\" fill=\"#C9D2D4\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "14",
      "height": "13",
      "viewBox": "0 0 14 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});