define("ember-svg-jar/inlined/arrow-all-directions-dash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M17 6l-4-4-4 4m0 7l4 4 4-4\" stroke=\"#767676\" stroke-width=\"2\"/><path fill=\"#767676\" d=\"M12 6.5h2v2h-2zm0 4h2v2h-2z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "18",
      "viewBox": "0 0 24 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});