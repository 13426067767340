define("ember-svg-jar/inlined/icon-profile-squarespace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.494 5.94l3.802-3.802c.259-.259.602-.401.969-.401.366 0 .71.142.968.4l.296.296.593-.592-.297-.296a2.192 2.192 0 00-1.56-.646c-.59 0-1.145.23-1.561.646L1.9 5.347l.593.593z\" fill=\"#030303\"/><mask id=\"a\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"8\" height=\"8\"><path d=\"M0 .793h7.57V7.84H0V.793z\" fill=\"#fff\"/></mask><g mask=\"url(#a)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.57 3.393L6.977 2.8 3.175 6.603a1.371 1.371 0 01-1.937 0 1.371 1.371 0 010-1.937l3.281-3.28-.593-.593-3.28 3.28a2.21 2.21 0 000 3.123c.416.416.97.645 1.56.645.59 0 1.145-.23 1.561-.646L7.57 3.393z\" fill=\"#030303\"/></g><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.354 4.074a2.193 2.193 0 00-1.56-.646c-.59 0-1.145.23-1.561.646L4.43 7.876l.593.593 3.802-3.802a1.371 1.371 0 012.337.968c0 .366-.142.71-.4.968L7.48 9.884l.593.592 3.28-3.28a2.21 2.21 0 000-3.122z\" fill=\"#030303\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.506 5.33L5.704 9.132a1.371 1.371 0 01-1.937 0l-.296-.296-.593.593.297.296c.416.416.97.645 1.56.645.59 0 1.145-.23 1.561-.646L10.1 5.922l-.593-.592z\" fill=\"#030303\"/>",
    "attrs": {
      "width": "12",
      "height": "15",
      "viewBox": "0 0 12 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});