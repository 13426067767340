define("ember-svg-jar/inlined/eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.418 9.158C17.148 6.678 14.631 5 11.75 5 8.869 5 6.352 6.68 5.082 9.158A.9.9 0 005 9.5a.9.9 0 00.082.342C6.352 12.322 8.869 14 11.75 14c2.881 0 5.398-1.68 6.668-4.158A.9.9 0 0018.5 9.5a.9.9 0 00-.082-.342zm-6.668 3.717a3.376 3.376 0 010-6.75c1.8 0 3.274 1.413 3.37 3.19l.005.187c0 1.8-1.412 3.272-3.188 3.368l-.187.005zm0-5.625a2.246 2.246 0 11-2.24 2.245c0-.165.036-.429.079-.588.163.12.461.218.665.218.619 0 1.121-.502 1.121-1.121a1.31 1.31 0 00-.218-.665 2.64 2.64 0 01.593-.089z\" fill=\"#1362BD\"/>",
    "attrs": {
      "width": "24",
      "height": "18",
      "viewBox": "0 0 24 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});