define("ember-svg-jar/inlined/envelope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.125 0c.52 0 .963.182 1.328.547.365.364.547.807.547 1.328v11.25c0 .52-.182.964-.547 1.328a1.808 1.808 0 01-1.328.547H1.875c-.52 0-.964-.182-1.328-.547A1.808 1.808 0 010 13.125V1.875C0 1.355.182.911.547.547A1.808 1.808 0 011.875 0h16.25zm0 1.875H1.875v1.602c.911.729 2.67 2.109 5.273 4.14l.352.313c.52.442.937.755 1.25.937.495.339.911.508 1.25.508.339 0 .755-.17 1.25-.508.312-.182.73-.495 1.25-.937l.352-.313c2.604-2.005 4.362-3.385 5.273-4.14V1.875zm-16.25 11.25h16.25V5.859l-4.531 3.594c-.651.547-1.185.938-1.602 1.172-.703.417-1.367.625-1.992.625s-1.302-.208-2.031-.625c-.39-.234-.925-.625-1.602-1.172L1.875 5.86v7.266z\" fill=\"#5F727B\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "20",
      "height": "15",
      "viewBox": "0 0 20 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});