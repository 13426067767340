define("ember-svg-jar/inlined/icon-no-cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 0a6 6 0 00-5.986 6.41 5 5 0 00-.24 9.068l-2.43 2.403c-.398.383-.436 1.078-.077 1.45.383.397.966.453 1.453-.014.486-.467 11.327-11.192 11.327-11.192l-.002-.002 5.667-5.607c.399-.383.437-1.078.078-1.45-.383-.396-.966-.452-1.453.014-.149.143-1.27 1.25-2.755 2.716A6.001 6.001 0 009 0zM6.012 12.274l-1.67 1.654a3.001 3.001 0 01-.002-5.855 1 1 0 00.757-1.195A4 4 0 1112.92 5.2a1 1 0 00.055.184c-3.194 3.157-6.966 6.89-6.966 6.89l.002.002z\" fill=\"#000\"/><path d=\"M17.57 8.194A4 4 0 0114 14h-2.83v-.002c.042-.002 0-.004 0-.004v.004l-.063.002h-.351v.002h-.093c-.674 0-1.056.445-1.058.997-.002.516.507.992 1.06.992h.09V16H14a6 6 0 005.348-8.722c-.31-.602-.882-.74-1.377-.488-.463.236-.654.91-.401 1.404z\" fill=\"#000\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});