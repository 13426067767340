define("ember-svg-jar/inlined/icon-linked-inventory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.2 1a1 1 0 011-1c.637 0 1.262.128 1.871.372.663.265 1.173.658 1.636 1.12.463.464.856.974 1.121 1.637.244.609.372 1.234.372 1.871s-.128 1.262-.372 1.871c-.261.655-.648 1.16-1.104 1.619l-.984 1.083a.95.95 0 01-.033.034l-3.2 3.2c-.463.463-.973.856-1.636 1.121A5.012 5.012 0 0111 14.3a5.011 5.011 0 01-1.871-.372c-.663-.265-1.173-.658-1.636-1.12-.463-.464-.856-.974-1.121-1.637A5.144 5.144 0 016 9.2c0-.637.128-1.262.372-1.871.265-.663.658-1.173 1.12-1.636l1.1-1.1a1 1 0 011.415 1.414l-1.1 1.1c-.337.337-.544.627-.679.964A3.014 3.014 0 008 9.2c0 .476.077.85.228 1.229.135.337.342.627.68.964.336.337.626.544.963.678.391.157.766.229 1.129.229s.738-.072 1.129-.229c.337-.134.627-.341.964-.678l3.183-3.183.984-1.083a.95.95 0 01.033-.034c.337-.337.544-.627.678-.964.157-.391.229-.766.229-1.129s-.072-.738-.229-1.129c-.134-.337-.341-.627-.678-.964-.337-.337-.627-.544-.964-.679A3.014 3.014 0 0015.2 2a1 1 0 01-1-1zM9.3 2.5c-.363 0-.738.072-1.129.228-.337.135-.627.342-.964.68L3.924 6.69 2.94 7.773l-.033.034c-.337.337-.544.627-.679.964A3.014 3.014 0 002 9.9c0 .363.072.738.228 1.129.135.337.342.627.68.964.336.337.626.544.963.678.391.157.766.229 1.129.229a1 1 0 110 2 5.011 5.011 0 01-1.871-.372c-.663-.265-1.173-.658-1.636-1.12-.463-.464-.856-.974-1.121-1.637A5.012 5.012 0 010 9.9c0-.637.128-1.262.372-1.871.261-.655.648-1.16 1.104-1.619l.984-1.083.033-.034 3.3-3.3c.463-.463.973-.856 1.636-1.121A5.012 5.012 0 019.3.5c.637 0 1.262.128 1.871.372.663.265 1.173.658 1.636 1.12.463.464.856.974 1.121 1.637.244.609.372 1.234.372 1.871s-.128 1.262-.372 1.871c-.262.656-.649 1.162-1.105 1.62l-1.086 1.185a1 1 0 01-1.474-1.352l1.1-1.2.03-.031c.337-.337.544-.627.678-.964.157-.391.229-.766.229-1.129s-.072-.738-.229-1.129c-.134-.337-.341-.627-.678-.964-.337-.337-.627-.544-.964-.679A3.014 3.014 0 009.3 2.5z\" fill=\"#000\"/>",
    "attrs": {
      "width": "21",
      "height": "15",
      "viewBox": "0 0 21 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});