define("ember-svg-jar/inlined/chevron-down-blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.97.97a.75.75 0 011.06 0L6 4.94 9.97.97a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 010-1.06z\" fill=\"#148CA7\"/>",
    "attrs": {
      "width": "12",
      "height": "7",
      "viewBox": "0 0 12 7",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});