define("ember-svg-jar/inlined/blue-waiting-on-you", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.083 26.517L3.333 38v10a3.334 3.334 0 003.334 3.333h26.666A3.333 3.333 0 0036.667 48V38l-5.75-11.483a3.332 3.332 0 00-2.984-1.85H12.067a3.333 3.333 0 00-2.984 1.85v0z\" fill=\"#EDF5F9\" stroke=\"#7EB6C4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M36.667 38h-10l-3.334 5h-6.666l-3.334-5h-10M20 5v14m7-7l-7 7-7-7\" stroke=\"#7EB6C4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "40",
      "height": "58",
      "viewBox": "0 0 40 58",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});