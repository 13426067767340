define("ember-svg-jar/inlined/icon-phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M3.833 4h4.49L9.77 7.618l-2.325 1.55A1 1 0 007 10c.003.094 0 .001 0 .001v.021a2.129 2.129 0 00.006.134c.006.082.016.193.035.33.04.27.114.642.26 1.08.294.88.87 2.019 1.992 3.141 1.122 1.122 2.261 1.698 3.14 1.992.439.146.81.22 1.082.26a4.424 4.424 0 00.463.04l.013.001h.008s.112-.006.001 0a1 1 0 00.895-.553l.67-1.34 4.435.74v4.32c-2.111.305-7.813.606-12.293-3.874C3.227 11.813 3.527 6.11 3.833 4zm5.24 6.486l1.807-1.204a2 2 0 00.747-2.407L10.18 3.257A2 2 0 008.323 2H3.781c-.909 0-1.764.631-1.913 1.617-.34 2.242-.801 8.864 4.425 14.09 5.226 5.226 11.848 4.764 14.09 4.425.986-.15 1.617-1.004 1.617-1.913v-4.372a2 2 0 00-1.671-1.973l-4.436-.739a2 2 0 00-2.118 1.078l-.346.693a4.696 4.696 0 01-.363-.105c-.62-.206-1.481-.63-2.359-1.508-.878-.878-1.302-1.739-1.508-2.36a4.583 4.583 0 01-.125-.447z\" fill=\"#0D0D0D\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});