define("ember-svg-jar/inlined/icon-row-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.102 1.361a1.25 1.25 0 00.058 1.767l.883.826.002.008 1.891 1.77-1.89 1.771-.003.009-.883.826a1.25 1.25 0 101.71 1.825l2.898-2.715 2.9 2.714a1.25 1.25 0 101.708-1.824l-.882-.826-.003-.009-1.89-1.77 1.89-1.77.003-.009.882-.826a1.25 1.25 0 10-1.709-1.825L6.768 4.018 3.87 1.303a1.25 1.25 0 00-1.767.058z\" fill=\"#252222\"/>",
    "attrs": {
      "width": "14",
      "height": "11",
      "viewBox": "0 0 14 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});