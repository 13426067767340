define("ember-svg-jar/inlined/icon-credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21 3a2 2 0 00-2-2H3a2 2 0 00-2 2v12a2 2 0 002 2h16a2 2 0 002-2V3zm-2.25 2H3.25V3.25h15.5V5zM3.25 8h15.5v6.75H3.25V8z\" fill=\"#252222\"/>",
    "attrs": {
      "width": "22",
      "height": "18",
      "viewBox": "0 0 22 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});