define("ember-svg-jar/inlined/plan-warn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#DB8260\"><path d=\"M12 0a9 9 0 110 18 9 9 0 010-18zm0 1.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15z\"/><path d=\"M13.85 12.838c0 .934-.751 1.693-1.675 1.693s-1.675-.76-1.675-1.693.751-1.692 1.675-1.692 1.675.76 1.675 1.692zm-3.156-8.605l.285 5.754c.013.27.234.482.502.482h1.388a.505.505 0 00.502-.482l.285-5.754a.505.505 0 00-.502-.533h-1.958a.505.505 0 00-.502.533z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "18",
      "viewBox": "0 0 24 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});