define("ember-svg-jar/inlined/heart-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.535 1.903a3.918 3.918 0 011.26 1.856 4.19 4.19 0 01.102 2.22 3.839 3.839 0 01-1.07 1.929L9.175 13.71A.93.93 0 018.5 14a.93.93 0 01-.674-.29L2.172 7.937a4.008 4.008 0 01-1.069-1.958 4.184 4.184 0 01.103-2.22 3.918 3.918 0 011.26-1.856 3.722 3.722 0 011.786-.842 3.982 3.982 0 011.963.145 4.17 4.17 0 011.7 1.074l.585.58.586-.58a4.157 4.157 0 011.699-1.074 3.982 3.982 0 011.963-.145 3.72 3.72 0 011.787.842z\" fill=\"#1362BD\" stroke=\"#1362BD\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "17",
      "height": "15",
      "viewBox": "0 0 17 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});