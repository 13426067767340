define("ember-svg-jar/inlined/arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14 3l6.55 6.003L14 15m6.5-6H3\" fill=\"none\" fill-rule=\"evenodd\" stroke=\"#1f96ad\" stroke-width=\"2\"/>",
    "attrs": {
      "width": "24",
      "height": "18",
      "viewBox": "0 -2 24 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});