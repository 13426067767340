define("ember-sortable/utils/defaults", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultA11yAnnouncementConfig = void 0;
  var defaultA11yAnnouncementConfig = {
    ACTIVATE: function ACTIVATE(_ref) {
      var a11yItemName = _ref.a11yItemName,
        index = _ref.index,
        maxLength = _ref.maxLength,
        direction = _ref.direction;
      var message = "".concat(a11yItemName, " at position, ").concat(index + 1, " of ").concat(maxLength, ", is activated to be repositioned.");
      if (direction === 'y') {
        message += 'Press up and down keys to change position,';
      } else {
        message += 'Press left and right keys to change position,';
      }
      message += ' Space to confirm new position, Escape to cancel.';
      return message;
    },
    MOVE: function MOVE(_ref2) {
      var a11yItemName = _ref2.a11yItemName,
        index = _ref2.index,
        maxLength = _ref2.maxLength,
        delta = _ref2.delta;
      return "".concat(a11yItemName, " is moved to position, ").concat(index + 1 + delta, " of ").concat(maxLength, ". Press Space to confirm new position, Escape to cancel.");
    },
    CONFIRM: function CONFIRM(_ref3) {
      var a11yItemName = _ref3.a11yItemName;
      return "".concat(a11yItemName, " is successfully repositioned.");
    },
    CANCEL: function CANCEL(_ref4) {
      var a11yItemName = _ref4.a11yItemName;
      return "Cancelling ".concat(a11yItemName, " repositioning");
    }
  };
  _exports.defaultA11yAnnouncementConfig = defaultA11yAnnouncementConfig;
});