define("ember-svg-jar/inlined/shopping-bag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M41.25 19.25H52.5v31.875c0 2.578-.918 4.785-2.754 6.621-1.836 1.836-4.043 2.754-6.621 2.754H9.375c-2.578 0-4.785-.918-6.621-2.754C.918 55.91 0 53.703 0 51.125V19.25h11.25V15.5c0-2.734.664-5.254 1.992-7.558a14.593 14.593 0 015.45-5.45C20.995 1.164 23.515.5 26.25.5s5.254.664 7.559 1.992a14.593 14.593 0 015.449 5.45c1.328 2.304 1.992 4.824 1.992 7.558v3.75zm-22.5-3.75v3.75h15V15.5c0-2.031-.742-3.789-2.227-5.273C30.04 8.742 28.281 8 26.25 8c-2.031 0-3.79.742-5.273 2.227-1.485 1.484-2.227 3.242-2.227 5.273zM37.5 29.563c.781 0 1.445-.274 1.992-.82.547-.548.82-1.212.82-1.993 0-.781-.273-1.445-.82-1.992a2.712 2.712 0 00-1.992-.82c-.781 0-1.445.273-1.992.82a2.712 2.712 0 00-.82 1.992c0 .781.273 1.445.82 1.992a2.71 2.71 0 001.992.82zm-22.5 0c.781 0 1.445-.274 1.992-.82.547-.548.82-1.212.82-1.993 0-.781-.273-1.445-.82-1.992a2.712 2.712 0 00-1.992-.82c-.781 0-1.445.273-1.992.82a2.712 2.712 0 00-.82 1.992c0 .781.273 1.445.82 1.992a2.71 2.71 0 001.992.82z\" fill=\"#4A4A4A\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "53",
      "height": "61",
      "viewBox": "0 0 53 61",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});