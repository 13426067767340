define("ember-radio-button/templates/components/radio-button-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9j62IBqE",
    "block": "[[],[],false,[]]",
    "moduleName": "ember-radio-button/templates/components/radio-button-input.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});