define("ember-svg-jar/inlined/twirl-triange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.5.5l-5.502 6L.5.5z\" fill=\"#FFF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "12",
      "height": "7",
      "viewBox": "0 0 12 7",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});