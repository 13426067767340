define("ember-svg-jar/inlined/left-nav-products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 23a3 3 0 013-3h13a3 3 0 110 6H14a3 3 0 01-3-3zM0 13a3 3 0 003 3h13a3 3 0 100-6H3a3 3 0 00-3 3zM11 3a3 3 0 003 3h13a3 3 0 100-6H14a3 3 0 00-3 3z\" fill=\"#AF97FF\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});