define("ember-svg-jar/inlined/left-nav-orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M3 20a6 6 0 006 6h18a6 6 0 00-6-6H3zM3 4a6 6 0 006 6h18a6 6 0 00-6-6H3zm0 8a6 6 0 006 6h18a6 6 0 00-6-6H3z\" fill=\"#AF97FF\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});