define("ember-svg-jar/inlined/package-mini", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.313 5.875L4.686 2.631M13.125 10V5a1.25 1.25 0 00-.625-1.08l-4.375-2.5a1.25 1.25 0 00-1.25 0L2.5 3.919A1.25 1.25 0 001.875 5v5a1.25 1.25 0 00.625 1.081l4.375 2.5a1.25 1.25 0 001.25 0l4.375-2.5a1.25 1.25 0 00.625-1.08z\" stroke=\"#7EB6C4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M2.044 4.35L7.5 7.506l5.456-3.156M7.5 13.8V7.5\" stroke=\"#7EB6C4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});