define("ember-svg-jar/inlined/icon-search-magnifier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M10 4.196a6 6 0 100 12 6 6 0 000-12zm-8 6A8 8 0 1116.32 15.1l5.387 5.388a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10.196z\" fill=\"#0D0D0D\"/>",
    "attrs": {
      "width": "24",
      "height": "25",
      "viewBox": "0 0 24 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});