define("ember-cropperjs/components/image-cropper-call", ["exports", "@ember/debug", "@ember/component", "@ember/object"], function (_exports, _debug, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var ImageCropperCallComponent = _component.default.extend({
    tagName: '',
    obj: null,
    func: null,
    args: null,
    onResp: null,
    params: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var _getProperties = (0, _object.getProperties)(this, 'obj', 'func', 'args', 'params'),
        obj = _getProperties.obj,
        func = _getProperties.func,
        args = _getProperties.args,
        params = _getProperties.params;
      if (args === null && params !== null) {
        if (func !== null) {
          args = params;
        } else {
          var _params = _toArray(params);
          func = _params[0];
          args = _params.slice(1);
        }
      }
      (false && !(_typeof(obj) === 'object' && obj !== null) && (0, _debug.assert)('image-cropper-call obj is required', _typeof(obj) === 'object' && obj !== null));
      (false && !(typeof func === 'string') && (0, _debug.assert)('image-cropper-call func is required and must be a string', typeof func === 'string'));
      (false && !(typeof obj[func] === 'function') && (0, _debug.assert)("image-cropper-call ".concat(func, " must be a function on ").concat(obj), typeof obj[func] === 'function'));
      this.sendAction('onResp', obj[func].apply(obj, args)); // eslint-disable-line ember/closure-actions
    }
  });

  ImageCropperCallComponent.reopenClass({
    positionalParams: 'params'
  });
  var _default = ImageCropperCallComponent;
  _exports.default = _default;
});