define("ember-svg-jar/inlined/logo-wix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FBBD71\" d=\"M178 2.3c-6 3-8.6 8.6-8.6 23.8 0 0 3-3 7.8-4.8 3.5-1.3 6-3 7.8-4.3 5.2-3.9 6-8.6 6-16.8-.1 0-8.3-.5-13 2.1z\"/><path d=\"M141.3 5.8c-5.2 4.3-6.5 11.7-6.5 11.7L118 81.9l-13.8-52.7c-1.3-5.6-3.9-12.5-7.8-17.3-4.8-6.1-14.8-6.5-16-6.5-.9 0-10.8.4-16 6.5-3.9 4.8-6.5 11.7-7.8 17.3l-13 52.7-16.8-64.4s-1.3-6.9-6.5-11.7C12.1-1.6 0 .2 0 .2l32 120.5s10.4.9 15.6-1.7c6.9-3.5 10.4-6 14.3-22.5C65.8 81.8 76.2 39 77 36c.4-1.3 1.3-5.2 3.9-5.2 2.6 0 3.5 3.5 3.9 5.2.9 3 11.2 45.8 15.1 60.5 4.3 16.4 7.3 19 14.3 22.5 5.2 2.6 15.6 1.7 15.6 1.7L161.6.2s-12.1-1.7-20.3 5.6zm49.6 13.8s-2.2 3-6.5 5.6c-3 1.7-5.6 2.6-8.6 4.3-5.2 2.6-6.5 5.2-6.5 9.1v81.7s8.2.9 13.4-1.7c6.9-3.5 8.2-6.9 8.2-21.6V19.6zm79.5 41.1L311 .6s-16.8-3-25.5 4.8c-5.6 4.8-11.2 13.8-11.2 13.8l-14.7 21.6c-.9 1.3-1.7 2.2-3 2.2s-2.6-1.3-3-2.2l-14.7-21.6s-6-8.6-11.2-13.8C219.1-2.4 202.2.6 202.2.6l39.3 60-40.2 60s17.7 2.2 26.4-5.6c5.6-4.8 10.8-13 10.8-13l14.7-21.6c.9-1.3 1.7-2.2 3-2.2s2.6 1.3 3 2.2l14.7 21.6s5.6 8.2 10.8 13c8.6 7.8 25.9 5.6 25.9 5.6l-40.2-59.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "311",
      "height": "121",
      "viewBox": "0 0 311 121"
    }
  };
  _exports.default = _default;
});