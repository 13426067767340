define("ember-svg-jar/inlined/chevron-right-blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.97 11.03a.75.75 0 010-1.06L4.94 6 .97 2.03A.75.75 0 012.03.97l4.5 4.5a.75.75 0 010 1.06l-4.5 4.5a.75.75 0 01-1.06 0z\" fill=\"#148CA7\"/>",
    "attrs": {
      "width": "7",
      "height": "12",
      "viewBox": "0 0 7 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});