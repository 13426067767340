define("ember-svg-jar/inlined/icon-dd-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M4.502 5.504L.662 1.477H.659A.579.579 0 01.674.664a.563.563 0 01.404-.16c2.277.043 4.646.041 6.844 0a.563.563 0 01.404.16.578.578 0 01.015.813l-3.84 4.027z\" fill=\"#252222\"/>",
    "attrs": {
      "width": "9",
      "height": "6",
      "viewBox": "0 0 9 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});