define("ember-svg-jar/inlined/left-nav-sales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M10 17a6 6 0 00-6 6v6a6 6 0 006-6v-6zm2 12a6 6 0 006-6V8a6 6 0 00-6 6v15zm8 0a6 6 0 006-6V1a6 6 0 00-6 6v22z\" fill=\"#AF97FF\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});