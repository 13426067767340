define("ember-svg-jar/inlined/collapse-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#C6D2D7\" fill-rule=\"evenodd\"><path d=\"M2.454.833l21.213 21.213-2.121 2.121L.333 2.954z\"/><path d=\"M.333 22.046L21.546.833l2.121 2.121L2.454 24.167z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "25",
      "viewBox": "0 0 24 25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});