define("ember-svg-jar/inlined/blue-package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M43.75 33.333V16.667a4.167 4.167 0 00-2.083-3.605L27.083 4.73a4.166 4.166 0 00-4.166 0L8.333 13.063a4.166 4.166 0 00-2.083 3.604v16.666a4.167 4.167 0 002.083 3.605l14.584 8.333a4.167 4.167 0 004.166 0l14.584-8.334a4.168 4.168 0 002.083-3.604z\" fill=\"#EDF5F9\" stroke=\"#7EB6C4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M34.375 19.583L15.625 8.771M6.813 14.5L25 25.02 43.188 14.5M25 46V25\" stroke=\"#7EB6C4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});