define("ember-svg-jar/inlined/circle-x-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M8 1.6a6.4 6.4 0 100 12.8A6.4 6.4 0 008 1.6zM0 8a8 8 0 1116 0A8 8 0 010 8zm4.634-3.366a.8.8 0 011.132 0L8 6.87l2.234-2.235a.8.8 0 011.132 1.132L9.13 8l2.235 2.234a.8.8 0 01-1.132 1.132L8 9.13l-2.234 2.235a.8.8 0 01-1.132-1.132L6.87 8 4.634 5.766a.8.8 0 010-1.132z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});