define("ember-svg-jar/inlined/icon-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11 18.778a7.778 7.778 0 100-15.556 7.778 7.778 0 000 15.556zM11 21c5.523 0 10-4.477 10-10S16.523 1 11 1 1 5.477 1 11s4.477 10 10 10z\" fill=\"#000\"/><path class=\"fill-current\" d=\"M10.17 12.767c-.125 0-.222-.083-.222-.207l-.014-.525c-.055-2.087 1.741-2.004 1.741-3.261 0-.608-.442-.953-1.022-.953-.746 0-1.106.58-1.3 1.036-.082.138-.193.138-.33.055l-.843-.525c-.25-.138-.277-.262-.235-.4.386-1.147 1.409-2.018 2.929-2.018 1.367 0 2.721.746 2.721 2.404 0 2.031-1.865 2.114-1.989 3.662l-.041.525c-.014.124-.083.207-.208.207H10.17zm-.54 2.03c0-.635.498-1.16 1.133-1.16.636 0 1.147.525 1.147 1.16 0 .623-.511 1.134-1.147 1.134a1.13 1.13 0 01-1.133-1.133z\" fill=\"#000\"/>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});