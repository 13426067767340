define("ember-svg-jar/inlined/icon-profile-shopify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\"><path d=\"M10.499 2.62a.128.128 0 00-.12-.11c-.056 0-1.02-.022-1.02-.022l-.888-.865c-.076-.077-.24-.055-.296-.033 0 0-.153.044-.405.12a3.208 3.208 0 00-.197-.482C7.288.68 6.86.384 6.356.384c-.033 0-.066 0-.11.011-.01-.022-.032-.033-.043-.055-.22-.24-.504-.35-.844-.34C4.7.024 4.044.495 3.518 1.339c-.373.592-.658 1.337-.745 1.918-.757.23-1.283.394-1.294.405-.383.12-.394.132-.438.493-.033.274-1.041 8-1.041 8l8.373 1.447 3.627-.9c-.011-.01-1.49-10.016-1.501-10.082zm-3.146-.778c-.197.055-.416.131-.646.197 0-.329-.044-.8-.197-1.194.493.098.734.657.843.997zm-1.095.34c-.439.131-.921.284-1.403.427.131-.515.394-1.03.701-1.37.12-.12.285-.263.471-.35.198.383.242.92.23 1.293zM5.37.439c.153 0 .285.033.394.099-.175.098-.35.23-.515.405-.416.45-.734 1.15-.865 1.82l-1.151.35C3.463 2.061 4.35.472 5.37.44z\" fill=\"#95BF47\"/><path d=\"M10.378 2.51c-.055 0-1.02-.022-1.02-.022l-.887-.865a.209.209 0 00-.11-.055V13.59l3.628-.899s-1.48-10.005-1.49-10.082a.128.128 0 00-.12-.099z\" fill=\"#5E8E3E\"/><path d=\"M6.345 4.373L5.93 5.951s-.471-.219-1.03-.175c-.822.055-.822.57-.822.701.044.702 1.896.855 2.005 2.51.077 1.304-.69 2.192-1.797 2.258-1.337.087-2.071-.702-2.071-.702L2.5 9.338s.734.559 1.326.515a.53.53 0 00.515-.56c-.055-.92-1.567-.865-1.666-2.377-.077-1.271.756-2.565 2.597-2.674.713-.055 1.074.131 1.074.131z\" fill=\"#fff\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h12v14H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "12",
      "height": "14",
      "viewBox": "0 0 12 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});