define("ember-wormhole/templates/components/ember-wormhole", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Qi9LBIoi",
    "block": "[[[1,[28,[35,0],[[33,1]],null]],[18,1,null],[1,[28,[35,0],[[33,3]],null]]],[\"&default\"],false,[\"unbound\",\"_wormholeHeadNode\",\"yield\",\"_wormholeTailNode\"]]",
    "moduleName": "ember-wormhole/templates/components/ember-wormhole.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});