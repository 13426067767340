define("ember-svg-jar/inlined/icon-syncing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M7.633 22.84a1 1 0 001.414-1.414l-1.293-1.293H9.34c4.652 0 8.5-3.847 8.5-8.5a8.36 8.36 0 00-.849-3.673c-.307-.598-.876-.737-1.37-.489-.462.232-.658.9-.41 1.395l-.003.001a6.368 6.368 0 01.632 2.766c0 3.548-2.952 6.5-6.5 6.5H7.754l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3zm2.734-21.68a1 1 0 10-1.414 1.414l1.293 1.293H8.66c-4.652 0-8.5 3.847-8.5 8.5a8.36 8.36 0 00.848 3.673h.002c.306.598.875.737 1.37.489.461-.232.657-.9.41-1.395l.002-.001a6.367 6.367 0 01-.632-2.766c0-3.548 2.952-6.5 6.5-6.5h1.586L8.953 7.16a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3z\"/>",
    "attrs": {
      "width": "18",
      "height": "24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});