define("ember-svg-jar/inlined/save-heart-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.782 4.82C19.163 2.61 17.28 1 15.055 1c-2.1 0-3.522 1.374-4.556 2.851C9.466 2.374 8.043 1 5.943 1 3.84 1 2.042 2.446 1.33 4.473 1.037 5.16 1 6.395 1 6.395 1 12.784 7.482 19 10.133 19h.733c2.436 0 8.105-5.252 9.007-11.061 0 0 .339-1.79-.09-3.12z\" stroke=\"#fff\" stroke-width=\"2\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "21",
      "height": "20",
      "viewBox": "0 0 21 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});