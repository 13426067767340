define("ember-cp-validations/-private/ember-internals", ["exports", "@ember/-internals/metal/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDependentKeys = getDependentKeys;
  _exports.isDescriptor = isDescriptor;
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function getDependentKeys(descriptorOrDecorator) {
    if (_index.default && _index.default.descriptorForDecorator) {
      var descriptor = _index.default.descriptorForDecorator(descriptorOrDecorator);
      return descriptor._dependentKeys || [descriptor.altKey];
    } else {
      return descriptorOrDecorator._dependentKeys;
    }
  }
  function isDescriptor(o) {
    if (_index.default && _index.default.isClassicDecorator) {
      return _index.default.isClassicDecorator(o);
    } else {
      return o && (_typeof(o) === 'object' || typeof o === 'function') && o.isDescriptor;
    }
  }
});