define("ember-svg-jar/inlined/icon-4-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.333 2C2.597 2 2 2.597 2 3.333v9.334C2 13.403 2.597 14 3.333 14h9.334c.736 0 1.333-.597 1.333-1.333V3.333C14 2.597 13.403 2 12.667 2H3.333zM12 12H9V9h3v3zm0-5H9V4h3v3zM7 7V4H4v3h3zM4 9v3h3V9H4z\" fill=\"#252222\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});