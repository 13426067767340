define("ember-svg-jar/inlined/left-nav-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.097.39a1.305 1.305 0 010 1.886l-5.5 5.333a1.404 1.404 0 01-1.944 0L.903 4.943a1.305 1.305 0 010-1.886 1.406 1.406 0 011.944 0l1.778 1.724L9.153.391a1.406 1.406 0 011.944 0z\" fill=\"#F9F8FD\"/>",
    "attrs": {
      "width": "12",
      "height": "8",
      "viewBox": "0 0 12 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});