define("ember-svg-jar/inlined/icon-tablet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 8a4 4 0 014-4h24a4 4 0 014 4v32a4 4 0 01-4 4H12a4 4 0 01-4-4V8zm28 0H12v32h24V8z\" fill=\"#585151\"/><path class=\"fill-current\" d=\"M26 36a2 2 0 11-4 0 2 2 0 014 0z\" fill=\"#585151\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});