define("ember-svg-jar/inlined/lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.375 7.25h-.563V5.562A3.567 3.567 0 0012.25 2a3.567 3.567 0 00-3.563 3.562V7.25h-.562C7.504 7.25 7 7.754 7 8.375v5.5C7 14.496 7.504 15 8.125 15h8.25c.621 0 1.125-.504 1.125-1.125v-5.5c0-.621-.504-1.125-1.125-1.125zm-2.438 0h-3.375V5.562c0-.93.758-1.687 1.688-1.687.93 0 1.687.757 1.687 1.687V7.25z\" fill=\"#1362BD\"/>",
    "attrs": {
      "width": "24",
      "height": "18",
      "viewBox": "0 0 24 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});