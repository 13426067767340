define("ember-svg-jar/inlined/icon-dollarcard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M19.6 25.857c.166.094.705-4.78 1.618-14.62 0 .187-6.638-3.26-19.914-10.344 0-.188 6.099 8.133 18.297 24.964z\" fill=\"#E1EDEF\"/><g fill=\"#7797A3\" fill-rule=\"nonzero\"><path d=\"M20.665 10.54L2.548.143a1.052 1.052 0 00-1.054 0L.527.83A1.057 1.057 0 000 1.744v14.38c0 .377.201.726.527.914l18.117 10.4a1.051 1.051 0 001.054 0l.967-.69c.326-.188.527-.536.527-.914V11.455c0-.377-.201-.726-.527-.914zm-2.812 14.238l-15.36-8.39c-.615-.378-.922-1.026-.922-1.944L1.569 3.006c0-.29.132-.539.397-.745.138-.108.605-.226 1.112.058l16.376 9.805-.096 12.405-.364.375c-.19.288-.57.246-1.14-.126z\"/><path d=\"M9.641 17.052c.445.119.82.043 1.122-.23.3-.277.443-.677.433-1.198a2.78 2.78 0 00-.346-1.272 2.83 2.83 0 00-.828-.977L8.43 12.358a.592.592 0 01-.227-.255.809.809 0 01-.082-.38.49.49 0 01.128-.341.279.279 0 01.298-.085l.975.272c.162.045.313.137.453.275.14.14.257.165.348.077l.411-.36a.292.292 0 00.082-.257.48.48 0 00-.124-.27c-.326-.403-.681-.669-1.07-.8l-.001-.682a.463.463 0 00-.086-.256c-.056-.085-.118-.136-.186-.153l-.54-.137c-.068-.017-.13.002-.188.058a.284.284 0 00-.087.21l-.007.67a1.134 1.134 0 00-1.058.303c-.297.28-.454.665-.469 1.158-.012.41.083.811.287 1.204.205.394.474.711.805.95l1.614 1.044a.64.64 0 01.235.264.86.86 0 01.093.392.47.47 0 01-.125.346c-.084.086-.187.11-.309.072l-1.02-.327a1.175 1.175 0 01-.472-.298c-.144-.143-.266-.175-.365-.095l-.449.316a.253.253 0 00-.098.238c.01.1.05.19.123.27.338.416.717.71 1.137.877l-.007.67a.468.468 0 00.09.264c.062.09.13.15.206.175l.6.204a.206.206 0 00.206-.036.245.245 0 00.093-.202l-.002-.682z\"/></g></g>",
    "attrs": {
      "width": "22",
      "height": "28",
      "viewBox": "0 0 22 28",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});