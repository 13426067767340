define("ember-svg-jar/inlined/icon-spin-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M22.164 11.127a1.138 1.138 0 00-1.812-1.378l-1.26 1.656c-.455.498-.416.553-1.011.54-.556-.012-.695.083-1.244-.233l-1.657-1.26a1.138 1.138 0 00-1.378 1.812l3.844 2.924c.5.38 1.214.283 1.595-.218l2.923-3.843z\" fill=\"#000\"/><path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.962 6.05a5.94 5.94 0 00-4.735 2.346 1.05 1.05 0 11-1.67-1.274A8.05 8.05 0 0119.012 12a1.05 1.05 0 11-2.099 0 5.95 5.95 0 00-5.95-5.95z\" fill=\"#000\"/>",
    "attrs": {
      "width": "23",
      "height": "23",
      "viewBox": "0 0 23 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});