define("ember-svg-jar/inlined/icon-cloud-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3 8a6 6 0 0111.671-1.963A6 6 0 0114 18H5a5 5 0 01-1.986-9.59A6.071 6.071 0 013 8zm6-4a4 4 0 00-3.903 4.879 1 1 0 01-.757 1.194A3.002 3.002 0 005 16h9a4 4 0 10-.08-8 1 1 0 01-1-.8A4.002 4.002 0 009 4z\" fill=\"#000\"/><path d=\"M9.21 14.404c-.37.36-1.013.456-1.48-.035l-1.87-1.966a1 1 0 01.02-1.414 1 1 0 011.413.03l1.222 1.28s2.553-2.437 3.04-2.904c.486-.466 1.069-.41 1.452-.013.359.371.32 1.067-.078 1.449l-3.72 3.573z\" fill=\"#000\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});