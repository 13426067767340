define("ember-svg-jar/inlined/star-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.456 1.09L6.892 6.299l-.522.075-5.23.752c-.056.009-.067.016-.088.043-.042.056-.054.093-.052.148.002.032.008.047.039.078l4.182 4.063-.09.52-.896 5.234c-.007.047-.003.071.026.117.018.03.021.032.056.04.043.012.06.01.122-.024l5.162-2.68.461.24 4.72 2.45c.043.023.06.026.104.015.034-.009.037-.011.056-.04.028-.047.033-.07.026-.113l-.986-5.759.378-.367 3.793-3.686c.041-.041.048-.056.05-.088.002-.055-.01-.092-.053-.148-.02-.027-.031-.034-.078-.041l-5.761-.829-.233-.473-2.33-4.732c-.03-.06-.04-.07-.062-.077a.226.226 0 00-.169 0c-.021.008-.032.017-.06.075z\" stroke=\"#D3DCCE\" stroke-width=\"2\" fill=\"none\"/>",
    "attrs": {
      "width": "20",
      "height": "19",
      "viewBox": "0 0 20 19",
      "class": "star-empty",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});