define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "markdown-editor": {
      "formats": {
        "bold": {
          "tooltip": "Make Selection Bold"
        },
        "heading": {
          "tooltip": "Add Heading"
        },
        "help": {
          "href": "https://guides.github.com/features/mastering-markdown/",
          "tooltip": "What is Markdown?"
        },
        "hr": {
          "tooltip": "Horizontal rule"
        },
        "image": {
          "prompt": "Enter the image URL",
          "tooltip": "Add Embedded Image"
        },
        "italic": {
          "tooltip": "Make Selection Italic"
        },
        "link": {
          "prompt": "Enter your link address. Include http://.",
          "tooltip": "Make Selection a Link"
        },
        "list-ol": {
          "tooltip": "Add Ordered List"
        },
        "list-ul": {
          "tooltip": "Add Bulletted List"
        },
        "quote": {
          "tooltip": "Add Quoted Text"
        },
        "table": {
          "tooltip": "Add table"
        },
        "undo": {
          "tooltip": "Undo"
        }
      },
      "modal": {
        "cancel": "Cancel",
        "confirm": "Confirm",
        "selectionText": "Please make a text selection"
      }
    }
  }]];
  _exports.default = _default;
});