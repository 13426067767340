define("ember-svg-jar/inlined/icon-magnify-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.75 5.75a4 4 0 11-8 0 4 4 0 018 0zm-.658 4.368a5.5 5.5 0 111.056-1.065l4.911 4.885A.75.75 0 0114.001 15l-4.909-4.883z\" fill=\"#000\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});