define("ember-svg-jar/inlined/icon-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"animate-spin origin-center duration-3s\" d=\"M18 36C8.06 36 0 27.94 0 18S8.06 0 18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18zm0-32.4C10.047 3.6 3.6 10.047 3.6 18S10.047 32.4 18 32.4 32.4 25.953 32.4 18 25.953 3.6 18 3.6z\" fill=\"#E6E0FB\"/><path class=\"animate-spin-reverse origin-center duration-2s\" d=\"M18 36C8.06 36 0 27.94 0 18S8.06 0 18 0c9.941 0 18 8.059 18 18s-8.059 18-18 18zm0-32.4C10.047 3.6 3.6 10.047 3.6 18S10.047 32.4 18 32.4 32.4 25.953 32.4 18 25.953 3.6 18 3.6z\" fill=\"#E6E0FB\"/><path class=\"animate-spin-reverse origin-center duration-4s\" opacity=\".7\" d=\"M2.581 8.713c-.948 1.574-1.403 2.616-1.904 4.5-.269 1.01.452 2.007 1.492 2.112.997.1 1.851-.671 2.093-1.643.328-1.321.908-2.29 1.403-3.112.415-.69 1.25-1.845 2.15-2.761.766-.782 1.094-2.236.417-3.098-.495-.63-1.393-.794-2.019-.294C4.414 5.855 3.258 7.59 2.581 8.713z\" fill=\"#451CCB\"/><path class=\"animate-spin origin-center duration-2500\" opacity=\".4\" d=\"M18 1.8c0-.994.808-1.81 1.797-1.71A18 18 0 113.17 28.2c-.563-.82-.238-1.92.634-2.399.87-.479 1.957-.152 2.54.653A14.4 14.4 0 1019.796 3.712C18.81 3.588 18 2.794 18 1.8z\" fill=\"#451CCB\"/><path class=\"animate-spin origin-center duration-4s\" opacity=\".6\" d=\"M2.581 8.713c-.948 1.574-1.403 2.616-1.904 4.5-.269 1.01.452 2.007 1.492 2.112.997.1 1.851-.671 2.093-1.643.328-1.321.908-2.29 1.403-3.112.415-.69 1.25-1.845 2.15-2.761.766-.782 1.094-2.236.417-3.098-.495-.63-1.393-.794-2.019-.294C4.414 5.855 3.258 7.59 2.581 8.713z\" fill=\"#451CCB\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});