define("ember-svg-jar/inlined/split-button-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M.263 1.557L6.503 8l6.24-6.443A.918.918 0 0012.061 0a.922.922 0 00-.647.282L6.503 5.357 1.585.282A.922.922 0 000 .902a.918.918 0 00.258.655h.005z\" fill=\"#451CCB\"/>",
    "attrs": {
      "width": "13",
      "height": "8",
      "class": "fill-current",
      "viewBox": "0 0 13 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});