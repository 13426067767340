define("ember-svg-jar/inlined/clock-mini", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.5 13.75a6.25 6.25 0 100-12.5 6.25 6.25 0 000 12.5z\" stroke=\"#7EB6C4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M7.5 3.75V7.5L10 8.75\" stroke=\"#7EB6C4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});