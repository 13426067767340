define("ember-svg-jar/inlined/icon-expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M21.88 5.4h-4.966A.907.907 0 0116 4.5c0-.497.41-.9.914-.9h4.965l-2.096-2.064a.89.89 0 010-1.272.924.924 0 011.293 0l3.656 3.6a.893.893 0 010 1.272l-3.656 3.6a.924.924 0 01-1.293 0 .89.89 0 010-1.272L21.88 5.4z\" fill=\"#382672\"/><path class=\"fill-current\" d=\"M21.88 5.4h-4.966A.907.907 0 0116 4.5c0-.497.41-.9.914-.9h4.965l-2.096-2.064a.89.89 0 010-1.272.924.924 0 011.293 0l3.656 3.6a.893.893 0 010 1.272l-3.656 3.6a.924.924 0 01-1.293 0 .89.89 0 010-1.272L21.88 5.4zm-18.76 0h4.966A.907.907 0 009 4.5c0-.497-.41-.9-.914-.9H3.12l2.096-2.064a.89.89 0 000-1.272.924.924 0 00-1.293 0l-3.656 3.6a.893.893 0 000 1.272l3.656 3.6a.924.924 0 001.293 0 .89.89 0 000-1.272L3.12 5.4zM14 4.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z\" fill=\"#382672\"/>",
    "attrs": {
      "width": "25",
      "height": "9",
      "viewBox": "0 0 25 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});