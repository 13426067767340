define("ember-svg-jar/inlined/icon-clip-payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M9.23 8.644c-1.737-.458-2.295-.933-2.295-1.672 0-.848.772-1.439 2.065-1.439 1.086 0 1.63.42 1.828 1.09.092.31.344.544.666.544h.23a.736.736 0 00.688-.988c-.322-.918-1.071-1.68-2.265-1.976v-.536C10.147 3.02 9.635 2.5 9 2.5s-1.147.521-1.147 1.167v.513c-1.484.327-2.678 1.307-2.678 2.808 0 1.796 1.461 2.69 3.596 3.212 1.912.467 2.294 1.151 2.294 1.874 0 .537-.374 1.393-2.065 1.393-1.262 0-1.912-.46-2.165-1.113-.114-.303-.375-.52-.688-.52h-.214a.74.74 0 00-.681 1.01c.436 1.082 1.453 1.72 2.6 1.968v.521c0 .646.513 1.167 1.148 1.167.635 0 1.147-.521 1.147-1.167v-.505c1.492-.288 2.678-1.167 2.678-2.761 0-2.21-1.86-2.964-3.596-3.423z\" fill=\"#252222\"/>",
    "attrs": {
      "width": "18",
      "height": "19",
      "viewBox": "0 0 18 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});