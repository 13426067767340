define("ember-svg-jar/inlined/icon-remove-image-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"12.153\" cy=\"12\" r=\"12\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.638 9.232a1.236 1.236 0 111.747-1.747l2.768 2.767 2.768-2.767a1.236 1.236 0 111.747 1.747L13.901 12l2.767 2.768a1.236 1.236 0 11-1.747 1.747l-2.768-2.767-2.768 2.767a1.236 1.236 0 11-1.747-1.747L10.406 12 7.638 9.232z\" fill=\"#585151\"/>",
    "attrs": {
      "width": "25",
      "height": "24",
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});