define("ember-svg-jar/inlined/play_circle_outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1 8.5a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0z\" fill=\"#148CA7\"/><path d=\"M9.83 9.32l-2.257 1.579A1 1 0 016 10.079V6.921a1 1 0 011.573-.82l2.257 1.58a1 1 0 010 1.638z\" fill=\"#ABE7F5\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});