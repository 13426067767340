define("ember-svg-jar/inlined/blue-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M28 15.333a11.173 11.173 0 01-1.2 5.067 11.333 11.333 0 01-10.133 6.267 11.173 11.173 0 01-5.067-1.2L4 28l2.533-7.6a11.173 11.173 0 01-1.2-5.067A11.333 11.333 0 0111.6 5.2 11.173 11.173 0 0116.667 4h.666A11.306 11.306 0 0128 14.667v.666z\" fill=\"#EDF5F9\" stroke=\"#7EB6C4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M19 31.167c-.006 2.2.508 4.37 1.5 6.333a14.166 14.166 0 0012.667 7.833c2.2.006 4.37-.508 6.333-1.5L49 47l-3.167-9.5a13.965 13.965 0 001.5-6.333A14.166 14.166 0 0039.5 18.5a13.968 13.968 0 00-6.333-1.5h-.834A14.134 14.134 0 0019 30.333v.834z\" fill=\"#EDF5F9\" stroke=\"#7EB6C4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><circle cx=\"27.5\" cy=\"31.5\" r=\"1.5\" fill=\"#7EB6C4\"/><circle cx=\"33.5\" cy=\"31.5\" r=\"1.5\" fill=\"#7EB6C4\"/><circle cx=\"39.5\" cy=\"31.5\" r=\"1.5\" fill=\"#7EB6C4\"/>",
    "attrs": {
      "width": "54",
      "height": "52",
      "viewBox": "0 0 54 52",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});