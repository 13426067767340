define("ember-svg-jar/inlined/link-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path class=\"stroke-current\" stroke=\"#1F96AD\" stroke-width=\"2\" d=\"M6 1h5v5\"/><path class=\"fill-current\" fill=\"#1F96AD\" fill-rule=\"nonzero\" d=\"M2.9 7.395l1.768-1.767 1.671 1.67-1.768 1.769zm3.12-3.109l3.6-3.6 1.672 1.671-3.6 3.6zM.3 10.012l1.254-1.254 1.67 1.671-1.253 1.254z\"/></g>",
    "attrs": {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});