define("ember-svg-jar/inlined/icon-add-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.758 13.258a1.258 1.258 0 110-2.516h3.984V6.758a1.258 1.258 0 112.516 0v3.984h3.984a1.258 1.258 0 010 2.516h-3.984v3.984a1.258 1.258 0 01-2.516 0v-3.984H6.758z\" fill=\"#585151\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});