define("ember-svg-jar/inlined/icon-paginate-chev-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.916 18.707a1 1 0 010-1.414L15.209 12 9.916 6.707a1 1 0 111.414-1.414l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0z\" fill=\"#847D7D\"/>",
    "attrs": {
      "width": "25",
      "height": "24",
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});