define("ember-svg-jar/inlined/icon-van", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M24.25 11.75h-18A.25.25 0 016 11.5v-10c0-.144.112-.25.244-.25H19.46c1.289 0 1.985.496 2.38.969.42.503.545 1.04.563 1.128a2.422 2.422 0 00.016.076l.997 4.392c.056.248.166.48.321.682l.71.919a.25.25 0 01.052.152V11.5a.25.25 0 01-.25.25z\" fill=\"#fff\" stroke=\"#252222\" stroke-width=\"1.5\"/><path d=\"M17 5a1 1 0 011-1h1.4l.6 3h-3V5z\" stroke=\"#252222\" stroke-width=\"1.5\"/><circle cx=\"19.75\" cy=\"11.75\" r=\"1.75\" fill=\"#fff\" stroke=\"#252222\" stroke-width=\"1.5\"/><circle cx=\"10.75\" cy=\"11.75\" r=\"1.75\" fill=\"#fff\" stroke=\"#252222\" stroke-width=\"1.5\"/><path stroke=\"#000\" stroke-width=\"1.5\" stroke-linecap=\"round\" d=\"M10.25 4h-5.5M8.75 6.25h-5.5M8.25 8.5h-6.5\"/>",
    "attrs": {
      "width": "26",
      "height": "15",
      "viewBox": "0 0 26 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});