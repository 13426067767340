define("ember-svg-jar/inlined/twirl-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path stroke=\"#2285A1\" stroke-width=\"1.5\" d=\"M6.971 1.029L3.97 3.97.971 1.03\" fill=\"none\" stroke-linecap=\"round\"/>",
    "attrs": {
      "width": "8",
      "height": "5",
      "viewBox": "0 0 8 5",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});