define("ember-svg-jar/inlined/icon-bank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_7367_38749)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#000\"><path class=\"fill-current\" d=\"M3 7a1 1 0 011 1v7a1 1 0 11-2 0V8a1 1 0 011-1zM0 18a1 1 0 011-1h16a1 1 0 110 2H1a1 1 0 01-1-1zM7 7a1 1 0 011 1v7a1 1 0 11-2 0V8a1 1 0 011-1zm4 0a1 1 0 011 1v7a1 1 0 11-2 0V8a1 1 0 011-1zm4 0a1 1 0 011 1v7a1 1 0 11-2 0V8a1 1 0 011-1zM8.553.106a1 1 0 01.894 0l8 4a1 1 0 11-.894 1.788L9 2.118 1.447 5.894a1 1 0 01-.894-1.788l8-4z\"/></g>",
    "attrs": {
      "width": "18",
      "height": "19",
      "viewBox": "0 0 18 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});