define("ember-svg-jar/inlined/icon-nav-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"stroke-current\" d=\"M15.75 8.625a6.285 6.285 0 01-.675 2.85A6.376 6.376 0 019.375 15a6.285 6.285 0 01-2.85-.675L2.25 15.75l1.425-4.275A6.285 6.285 0 013 8.625a6.375 6.375 0 013.525-5.7 6.285 6.285 0 012.85-.675h.375a6.36 6.36 0 016 6v.375z\" stroke=\"#585151\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});