define("ember-svg-jar/inlined/icon-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.562 8.36v.93a.38.38 0 01-.116.281.384.384 0 01-.282.117H3.586a.384.384 0 01-.282-.117.384.384 0 01-.117-.282v-.93c0-.11.04-.204.117-.282a.384.384 0 01.282-.116h5.578c.11 0 .205.039.282.116a.384.384 0 01.116.282zm-5.976 2.39h5.578c.11 0 .205.039.282.116a.384.384 0 01.116.282v.93c0 .11-.038.205-.116.282a.384.384 0 01-.282.117H3.586a.384.384 0 01-.282-.117.384.384 0 01-.117-.282v-.93c0-.11.04-.204.117-.282a.384.384 0 01.282-.116zm9.164-6.242V15.53c0 .443-.155.82-.465 1.13a1.54 1.54 0 01-1.129.464H1.594c-.443 0-.82-.155-1.13-.465A1.537 1.537 0 010 15.531V1.72C0 1.277.155.9.465.59.775.28 1.15.126 1.594.126h6.773c.443 0 .82.155 1.13.465l2.788 2.789c.31.31.465.686.465 1.129zM8.5 1.852v2.523h2.523L8.5 1.852zm2.656 13.68V5.968H7.703a.768.768 0 01-.564-.233.768.768 0 01-.233-.564V1.719H1.594V15.53h9.562z\" fill=\"#B3C5C8\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "13",
      "height": "18",
      "viewBox": "0 0 13 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});