define("ember-cropperjs/components/image-cropper-on", ["exports", "@ember/debug", "@ember/component", "@ember/object", "@ember/utils", "@ember/runloop"], function (_exports, _debug, _component, _object, _utils, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    A component used for responding to cropper events. Typically used via the image-cropper
    contextual component with positional parameters like so:
  
    ```hbs
    {{#image-cropper
      alt='sinbad'
      source='sinbad.jpg'
      options=(hash
        viewMode=2
        width=256
        height=256)
    as |cropper|}}
  
    {{cropper.on 'crop' (action 'cropImage')}}
  
    {{/image-cropper}}
    ```
    @class ImageCropperOn
    @public
  */
  var ImageCropperOnComponent = _component.default.extend({
    tagName: '',
    cropper: null,
    eventSource: null,
    /**
      The [event from Cropper.js](https://github.com/fengyuanchen/cropperjs#events) to listen for.
       @argument event
      @type String
    */
    event: null,
    /**
      The action to call when the event is triggered.
       @argument action
      @type Action
    */
    action: null,
    _prevEvent: null,
    init: function init() {
      this._super.apply(this, arguments);
      this._boundOnEvent = (0, _runloop.bind)(this, this._onEvent);
    },
    _normalizedEvent: (0, _object.computed)('event', function () {
      var event = (0, _object.get)(this, 'event');
      (false && !(typeof event === 'string') && (0, _debug.assert)("image-cropper-event requires event to be a string, was ".concat(event), typeof event === 'string'));
      return event.toLowerCase();
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var _getProperties = (0, _object.getProperties)(this, 'eventSource', '_normalizedEvent', '_prevEvent', 'action'),
        eventSource = _getProperties.eventSource,
        _normalizedEvent = _getProperties._normalizedEvent,
        _prevEvent = _getProperties._prevEvent,
        action = _getProperties.action;
      (false && !((0, _utils.isPresent)(eventSource)) && (0, _debug.assert)('image-cropper-event requires an eventSource', (0, _utils.isPresent)(eventSource)));
      (false && !((0, _utils.isPresent)(action)) && (0, _debug.assert)('image-cropper-event requires an action', (0, _utils.isPresent)(action)));
      if (_normalizedEvent !== _prevEvent) {
        if (_prevEvent) {
          eventSource.removeEventListener(_prevEvent, this._boundOnEvent);
        }
        this._prevEvent = _normalizedEvent;
        eventSource.addEventListener(_normalizedEvent, this._boundOnEvent);
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      var _getProperties2 = (0, _object.getProperties)(this, 'eventSource', '_prevEvent'),
        eventSource = _getProperties2.eventSource,
        _prevEvent = _getProperties2._prevEvent;
      if (eventSource && _prevEvent) {
        eventSource.removeEventListener(_prevEvent, this._boundOnEvent);
      }
    },
    _onEvent: function _onEvent() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      var action = this.action;
      if (action) {
        action.apply(void 0, [this.cropper].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
  ImageCropperOnComponent.reopenClass({
    positionalParams: ['event', 'action']
  });
  var _default = ImageCropperOnComponent;
  _exports.default = _default;
});