define("ember-svg-jar/inlined/left-nav-customers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M5 33a8 8 0 008-8v-9a8 8 0 00-8 8v9zm19-6a8 8 0 01-8-8v-9a8 8 0 018 8v9z\" fill=\"#AF97FF\"/><circle class=\"fill-current\" cx=\"9.5\" cy=\"10\" r=\"4\" fill=\"#AF97FF\"/><circle class=\"fill-current\" cx=\"19.5\" cy=\"4\" r=\"4\" transform=\"rotate(-180 19.5 4)\" fill=\"#AF97FF\"/>",
    "attrs": {
      "width": "30",
      "height": "33",
      "viewBox": "0 0 30 33",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});