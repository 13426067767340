define("ember-svg-jar/inlined/calendar-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13 2.5H3A1.5 1.5 0 001.5 4v9A1.5 1.5 0 003 14.5h10a1.5 1.5 0 001.5-1.5V4A1.5 1.5 0 0013 2.5z\" stroke=\"#fff\" stroke-linejoin=\"round\"/><path d=\"M12.432 2.5H3.568c-1.14 0-2.068.942-2.068 2.1V6h.25c0-.5.75-1 1.25-1h10c.5 0 1.25.5 1.25 1h.25V4.6c0-1.158-.928-2.1-2.068-2.1z\" fill=\"#fff\"/><path d=\"M4 1.5v1m8-1v1\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M6.107 6.5H3.893C3.4 6.5 3 6.9 3 7.393v2.214c0 .493.4.893.893.893h2.214c.493 0 .893-.4.893-.893V7.393C7 6.9 6.6 6.5 6.107 6.5z\" fill=\"#fff\"/><path d=\"M14.5 8V6.5A1.505 1.505 0 0013 5H3a1.504 1.504 0 00-1.5 1.5V8\" stroke=\"#fff\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});