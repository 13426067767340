define("ember-svg-jar/inlined/link-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M15 12c2.21 0 4-.79 4-3s-1.79-3-4-3-4 .79-4 3 1.79 3 4 3z\" stroke=\"#1362BD\"/><path d=\"M10 12c2.21 0 4-.79 4-3s-1.79-3-4-3-4 .79-4 3 1.79 3 4 3z\" stroke=\"#FFF\" stroke-width=\"3\"/><path d=\"M10 12c2.21 0 4-.79 4-3s-1.79-3-4-3-4 .79-4 3 1.79 3 4 3z\" stroke=\"#1362BD\"/><path d=\"M11 9c0 2.21 1.79 3 4 3s4-.79 4-3\" stroke=\"#FFF\" stroke-width=\"3\"/><path d=\"M11 9c0 2.21 1.79 3 4 3s4-.79 4-3\" stroke=\"#1362BD\"/></g>",
    "attrs": {
      "width": "24",
      "height": "18",
      "viewBox": "0 0 24 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});