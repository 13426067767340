define("ember-svg-jar/inlined/icon-edit-pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M16.293 2.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-13 13A1 1 0 018 21H4a1 1 0 01-1-1v-4a1 1 0 01.293-.707l10-10 3-3zM14 7.414l-9 9V19h2.586l9-9L14 7.414zm4 1.172L19.586 7 17 4.414 15.414 6 18 8.586z\"/><path class=\"fill-current\" d=\"M14 6l2.5-2L21 7l-3 2.5L14 6z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});