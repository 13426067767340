define("ember-svg-jar/inlined/include-product-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><filter x=\"-23.3%\" y=\"-23.3%\" width=\"173.3%\" height=\"173.3%\" filterUnits=\"objectBoundingBox\" id=\"a\"><feOffset dx=\"2\" dy=\"2\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur stdDeviation=\"1.5\" in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"/><feComposite in=\"shadowBlurOuter1\" in2=\"SourceAlpha\" operator=\"out\" result=\"shadowBlurOuter1\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.114328578 0\" in=\"shadowBlurOuter1\"/></filter><circle id=\"b\" cx=\"7.5\" cy=\"7.5\" r=\"7.5\"/></defs><g transform=\"translate(1 1)\" fill=\"none\" fill-rule=\"evenodd\"><use fill=\"#000\" filter=\"url(#a)\" xlink:href=\"#b\"/><circle stroke=\"#3D7A92\" stroke-width=\"2\" stroke-linejoin=\"square\" fill=\"#C0D9DE\" cx=\"7.5\" cy=\"7.5\" r=\"6.5\"/></g>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});