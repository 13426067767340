define("ember-svg-jar/inlined/ascending-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 7l4.502-7L12 7H3z\" fill=\"#123E4A\"/>",
    "attrs": {
      "width": "15",
      "height": "7",
      "viewBox": "0 0 15 7",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});