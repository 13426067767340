define("ember-svg-jar/inlined/arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10 3L3.45 9.003 10 15M3.5 9H21\" fill=\"none\" fill-rule=\"evenodd\" stroke=\"#1f96ad\" stroke-width=\"2\"/>",
    "attrs": {
      "width": "24",
      "height": "18",
      "viewBox": "0 -2 24 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});