define("ember-svg-jar/inlined/icon-alert-triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M12 14a1 1 0 01-1-1v-3a1 1 0 012 0v3a1 1 0 01-1 1zm-1.5 2.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z\" fill=\"#252222\"/><path class=\"fill-current\" d=\"M10.23 3.216c.75-1.425 2.79-1.425 3.54 0l8.343 15.852C22.814 20.4 21.85 22 20.343 22H3.657c-1.505 0-2.47-1.6-1.77-2.931L10.23 3.216zM20.344 20L12 4.147 3.656 20h16.688z\" fill=\"#252222\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});