define("ember-svg-jar/inlined/icon-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M8 2.667a.667.667 0 00-.667.666c0 1.128-1.364 1.694-2.161.896a.667.667 0 10-.943.943c.798.797.232 2.161-.896 2.161a.667.667 0 000 1.334c1.129 0 1.693 1.364.896 2.161a.667.667 0 10.943.943c.797-.798 2.161-.233 2.161.896a.667.667 0 001.334 0c0-1.128 1.364-1.694 2.161-.896a.667.667 0 10.943-.943c-.798-.797-.232-2.161.896-2.161a.667.667 0 000-1.334c-1.128 0-1.694-1.364-.896-2.161a.667.667 0 10-.943-.943c-.797.798-2.161.232-2.161-.896A.667.667 0 008 2.667zm-1.995.518a2 2 0 013.99 0 2 2 0 012.82 2.82 2 2 0 010 3.99 2 2 0 01-2.82 2.82 2 2 0 01-3.99 0 2 2 0 01-2.82-2.82 2 2 0 010-3.99 2 2 0 012.82-2.82z\" fill=\"#451CCB\"/><path class=\"fill-current\" d=\"M8 6.667a1.333 1.333 0 100 2.666 1.333 1.333 0 000-2.666zm-1.886-.553a2.667 2.667 0 113.772 3.772 2.667 2.667 0 01-3.772-3.772z\" fill=\"#451CCB\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});