define("ember-svg-jar/inlined/remove-circle-x-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#A19182\"><path d=\"M9.5 18a8.5 8.5 0 100-17 8.5 8.5 0 000 17z\" fill=\"#fff\"/><path d=\"M5.964 13.036l7.072-7.072m0 7.072L5.964 5.964\"/></g>",
    "attrs": {
      "width": "19",
      "height": "19",
      "viewBox": "0 0 19 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});