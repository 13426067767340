define("ember-svg-jar/inlined/trash-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.063 3.063l.546 8.75c.026.505.394.874.875.874h5.032c.483 0 .844-.369.875-.874l.546-8.75\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M2.188 3.063h9.624z\" fill=\"#fff\"/><path d=\"M2.188 3.063h9.624\" stroke=\"currentColor\" stroke-miterlimit=\"10\" stroke-linecap=\"round\"/><path d=\"M5.25 3.063V1.968a.654.654 0 01.656-.657h2.188a.654.654 0 01.656.657v1.093M7 4.813v6.125M5.031 4.813l.219 6.125m3.719-6.125l-.219 6.125\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});