define("ember-svg-jar/inlined/save-heart-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.844 3.82C18.223 1.61 16.332 0 14.1 0c-2.106 0-3.534 1.374-4.57 2.851C8.493 1.374 7.065 0 4.96 0 2.848 0 1.045 1.446.33 3.473.037 4.16 0 5.395 0 5.395 0 11.784 6.504 18 9.163 18h.736c2.443 0 8.13-5.252 9.036-11.061 0 0 .34-1.79-.091-3.12z\" fill=\"#EF826A\"/><path d=\"M18.844 3.82C18.223 1.61 16.332 0 14.1 0c-2.106 0-3.534 1.374-4.57 2.851C8.493 1.374 7.065 0 4.96 0 2.848 0 1.045 1.446.33 3.473.037 4.16 0 5.395 0 5.395 0 11.784 6.504 18 9.163 18h.736c2.443 0 8.13-5.252 9.036-11.061 0 0 .34-1.79-.091-3.12z\" fill=\"#EF826A\"/>",
    "attrs": {
      "width": "20",
      "height": "18",
      "viewBox": "0 0 20 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});