define("ember-svg-jar/inlined/pv-no", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 4c0 .72-.18 1.387-.54 2-.36.613-.847 1.1-1.46 1.46-.613.36-1.28.54-2 .54s-1.387-.18-2-.54A4.042 4.042 0 01.54 6 3.877 3.877 0 010 4c0-.72.18-1.387.54-2C.9 1.387 1.387.9 2 .54 2.613.18 3.28 0 4 0s1.387.18 2 .54C6.613.9 7.1 1.387 7.46 2c.36.613.54 1.28.54 2z\" fill=\"#C9D2D4\"/>",
    "attrs": {
      "width": "8",
      "height": "8",
      "viewBox": "0 0 8 8",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});