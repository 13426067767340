define("ember-svg-jar/inlined/image-cluster-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g filter=\"url(#a)\"><rect x=\"8.5\" y=\"8.5\" width=\"23\" height=\"15\" rx=\"1.5\" stroke=\"#fff\"/></g><path d=\"M23.5 15a1.5 1.5 0 100-3 1.5 1.5 0 000 3z\" stroke=\"#fff\" stroke-miterlimit=\"10\"/><path d=\"M23 19.442l-4.957-3.12c-.317-.198-.74-.313-1.186-.322-.445-.008-.878.092-1.213.279L8.5 20.5m8.5 3l7.694-5.148c.332-.212.772-.337 1.236-.35.465-.014.92.084 1.28.277l4.29 2.31\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><defs><filter id=\"a\" x=\"0\" y=\"0\" width=\"40\" height=\"32\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\"/><feOffset/><feGaussianBlur stdDeviation=\"4\"/><feColorMatrix values=\"0 0 0 0 0.0666667 0 0 0 0 0.0666667 0 0 0 0 0.0666667 0 0 0 0.16 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow\" result=\"shape\"/></filter></defs>",
    "attrs": {
      "width": "40",
      "height": "32",
      "viewBox": "0 0 40 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});