define("ember-svg-jar/inlined/ig-button-logo-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.996 2.3c2.67 0 2.984.012 4.04.06.976.044 1.504.206 1.857.345.468.183.801.397 1.15.747.35.349.568.682.747 1.15.135.354.301.882.345 1.859.047 1.056.06 1.37.06 4.041 0 2.672-.012 2.985-.06 4.041-.044.977-.206 1.505-.345 1.858a3.104 3.104 0 01-.746 1.151c-.35.35-.683.568-1.151.747-.353.135-.881.301-1.857.345-1.056.048-1.37.06-4.04.06s-2.984-.012-4.04-.06C4.98 18.6 4.452 18.438 4.1 18.3a3.103 3.103 0 01-1.15-.747 3.055 3.055 0 01-.747-1.15c-.135-.354-.301-.882-.345-1.859-.047-1.056-.06-1.37-.06-4.041 0-2.672.013-2.985.06-4.041.044-.977.206-1.505.345-1.858.183-.469.397-.802.746-1.151.35-.35.683-.568 1.151-.747.353-.135.881-.301 1.857-.345 1.056-.052 1.373-.06 4.04-.06zm0-1.802c-2.714 0-3.056.012-4.123.06-1.063.047-1.79.218-2.425.464-.658.254-1.214.6-1.77 1.155a4.96 4.96 0 00-1.154 1.77C.278 4.584.107 5.31.06 6.378.012 7.441 0 7.783 0 10.498c0 2.715.012 3.057.06 4.125.047 1.064.218 1.79.464 2.43.254.658.599 1.214 1.155 1.77a4.959 4.959 0 001.77 1.155c.634.246 1.36.417 2.428.464 1.067.048 1.405.06 4.123.06s3.056-.012 4.123-.06c1.064-.047 1.79-.218 2.429-.464a4.872 4.872 0 001.77-1.155 4.959 4.959 0 001.154-1.77c.246-.636.417-1.362.465-2.43.047-1.068.059-1.406.059-4.125 0-2.72-.012-3.057-.06-4.125-.047-1.064-.218-1.79-.464-2.43a4.875 4.875 0 00-1.155-1.77 4.957 4.957 0 00-1.77-1.155c-.634-.246-1.36-.417-2.428-.464C13.052.51 12.71.498 9.996.498z\" fill=\"#fff\"/><path d=\"M9.996 5.365a5.137 5.137 0 00-5.135 5.137 5.134 5.134 0 1010.27 0 5.134 5.134 0 00-5.135-5.137zm0 8.468a3.334 3.334 0 01-3.333-3.335 3.334 3.334 0 116.666 0 3.334 3.334 0 01-3.333 3.335zM15.334 6.357a1.199 1.199 0 100-2.397 1.199 1.199 0 000 2.397z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "20",
      "height": "21",
      "viewBox": "0 0 20 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});