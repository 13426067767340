define("ember-svg-jar/inlined/state-line-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M215.029 1.5H-.001\" stroke=\"#D1E2F4\" stroke-width=\"2\" fill=\"none\"/>",
    "attrs": {
      "width": "216",
      "height": "3",
      "viewBox": "0 0 216 3",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});