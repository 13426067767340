define("ember-data-model-fragments/transforms/fragment-array", ["exports", "ember-data-model-fragments/transforms/fragment"], function (_exports, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    @module ember-data-model-fragments
  */

  /**
    Transform for `MF.fragmentArray` fragment attribute which delegates work to
    the fragment type's serializer
  
    @class FragmentArrayTransform
    @namespace MF
    @extends DS.Transform
  */
  var FragmentArrayTransform = _fragment.default.extend({
    deserialize: function deserializeFragmentArray(data) {
      var _this = this;
      if (data == null) {
        return null;
      }
      return data.map(function (datum) {
        return _this.deserializeSingle(datum);
      }, this);
    },
    serialize: function serializeFragmentArray(snapshots) {
      if (!snapshots) {
        return null;
      }
      var store = this.store;
      return snapshots.map(function (snapshot) {
        var realSnapshot = snapshot._createSnapshot ? snapshot._createSnapshot() : snapshot;
        var serializer = store.serializerFor(realSnapshot.modelName || realSnapshot.constructor.modelName);
        return serializer.serialize(realSnapshot);
      });
    }
  });
  var _default = FragmentArrayTransform;
  _exports.default = _default;
});