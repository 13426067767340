define("ember-svg-jar/inlined/valid-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(1 1)\" fill=\"none\" fill-rule=\"evenodd\"><circle stroke=\"#95BF47\" fill=\"#FFF\" fill-rule=\"nonzero\" cx=\"7.5\" cy=\"8.5\" r=\"7.5\"/><path stroke=\"#5E8E3E\" stroke-width=\"2\" d=\"M4.5 7.198l5.116 3.851L18.621 0\"/></g>",
    "attrs": {
      "width": "21",
      "height": "18",
      "viewBox": "0 0 21 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});