define("ember-svg-jar/inlined/marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#2285A1\" cx=\"8\" cy=\"8\" r=\"8\"/><path d=\"M11.038 9.713l-3.38.091 4.18-4.18a.748.748 0 00.228-.548.748.748 0 00-.229-.548l-.365-.365a.748.748 0 00-.548-.229.748.748 0 00-.549.229l-4.18 4.18.092-3.38c0-.214-.08-.4-.24-.56a.767.767 0 00-.56-.24h-.502a.748.748 0 00-.548.228.748.748 0 00-.229.548l.023 6.053c0 .213.076.396.229.548a.748.748 0 00.548.229l6.053.023a.748.748 0 00.548-.229.748.748 0 00.228-.548v-.503c0-.213-.08-.4-.24-.56a.767.767 0 00-.56-.239z\" fill=\"#FFF\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});