define("ember-svg-jar/inlined/icon-cloud-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M9.815 2.738a4 4 0 00-3.998 4.103 1 1 0 01-.751.991 3.002 3.002 0 00.75 5.906h1a1 1 0 110 2h-1a5 5 0 01-1.971-9.596 6 6 0 0111.17-2.4 6 6 0 01-.2 11.996 1 1 0 110-2 4 4 0 10-.328-7.986 1 1 0 01-.998-.6 4.001 4.001 0 00-3.674-2.414zm.293 5.293a1 1 0 011.414 0l2 2a1 1 0 01-1.414 1.414l-.293-.293v7.586a1 1 0 11-2 0v-7.585l-.293.292a1 1 0 01-1.414-1.414l2-2z\" fill=\"#0D0D0D\"/>",
    "attrs": {
      "width": "21",
      "height": "20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});