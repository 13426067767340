define("ember-svg-jar/inlined/play-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21.11 14.074c.412.24.618.6.618 1.08 0 .48-.206.84-.617 1.08l-9.052 5.195c-.411.24-.823.24-1.234 0-.412-.24-.618-.6-.618-1.08V9.65c0-.48.206-.84.618-1.08.411-.24.823-.24 1.234 0l9.052 5.503zM30 15c0 2.702-.675 5.202-2.026 7.5a15.157 15.157 0 01-5.474 5.474C20.202 29.324 17.702 30 15 30s-5.202-.675-7.5-2.026A15.157 15.157 0 012.026 22.5C.676 20.202 0 17.702 0 15s.675-5.202 2.026-7.5A15.157 15.157 0 017.5 2.026C9.798.676 12.298 0 15 0s5.202.675 7.5 2.026A15.157 15.157 0 0127.974 7.5C29.324 9.798 30 12.298 30 15zM2.109 15c0 2.32.58 4.469 1.74 6.446a13 13 0 004.705 4.705c1.977 1.16 4.126 1.74 6.446 1.74 2.32 0 4.469-.58 6.446-1.74a13 13 0 004.705-4.705c1.16-1.977 1.74-4.126 1.74-6.446 0-2.32-.58-4.469-1.74-6.446a13 13 0 00-4.705-4.705c-1.977-1.16-4.126-1.74-6.446-1.74-2.32 0-4.469.58-6.446 1.74A13 13 0 003.85 8.554C2.69 10.531 2.11 12.68 2.11 15z\" fill=\"#A3C1C9\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});