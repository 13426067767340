define("ember-svg-jar/inlined/nav-dots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#2F728D\"><circle cx=\"2.5\" cy=\"2.5\" r=\"2.5\"/><circle cx=\"10.5\" cy=\"2.5\" r=\"2.5\"/><circle cx=\"18.5\" cy=\"2.5\" r=\"2.5\"/></g>",
    "attrs": {
      "width": "21",
      "height": "5",
      "viewBox": "0 0 21 5",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});