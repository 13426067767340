define("ember-svg-jar/inlined/icon-crop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"12.5\" cy=\"12.749\" r=\"12\" fill=\"#fff\"/><path d=\"M15.27 18.749v-8.77H6.5\" stroke=\"#585151\" stroke-width=\"2\" stroke-linecap=\"round\"/><path d=\"M9.73 6.749v8.769h8.77\" stroke=\"#585151\" stroke-width=\"2\" stroke-linecap=\"round\"/>",
    "attrs": {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});