define("ember-svg-jar/inlined/icon-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 1.5a.75.75 0 01.75.75v7.94l1.72-1.72a.75.75 0 111.06 1.06l-3 3a.75.75 0 01-1.06 0l-3-3a.75.75 0 011.06-1.06l1.72 1.72V2.25A.75.75 0 019 1.5zM3.75 12.75a.75.75 0 01.75.75V15h9v-1.5a.75.75 0 011.5 0V15a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 013 15v-1.5a.75.75 0 01.75-.75z\" fill=\"#0D0D0D\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});