define("ember-svg-jar/inlined/shopping-cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.25 42.167a1.917 1.917 0 100-3.834 1.917 1.917 0 000 3.834zm21.083 0a1.917 1.917 0 100-3.833 1.917 1.917 0 000 3.833z\" stroke=\"#F18162\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M44.16 11.5H11.04l4.14 17.94 2.3 1.38h19.78l2.76-1.38 4.14-17.94z\" fill=\"#FFF2EE\"/><path d=\"M1.917 1.917h7.666L14.72 27.58a3.833 3.833 0 003.833 3.085h18.63a3.833 3.833 0 003.834-3.085l3.066-16.08H11.5\" stroke=\"#F18162\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "46",
      "height": "46",
      "viewBox": "0 0 46 46",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});