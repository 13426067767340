define("ember-svg-jar/inlined/icon-toggle-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.5 15a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM3 7.644l3.106 3.08 5.592-5.544-.988-.98-4.604 4.564-2.118-2.079L3 7.644z\" fill=\"#4F46E5\"/>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});