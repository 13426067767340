define("ember-svg-jar/inlined/cluster-icon-lss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect x=\".5\" y=\".5\" width=\"23\" height=\"15\" rx=\"1.5\"/><path d=\"M23.51 7.833h-7.836M16.174 0v16\"/>",
    "attrs": {
      "width": "24",
      "height": "16",
      "viewBox": "0 0 24 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});