define("ember-radio-button/components/radio-button", ["exports", "@ember/component", "@ember/object", "@ember/utils", "ember-radio-button/templates/components/radio-button"], function (_exports, _component, _object, _utils, _radioButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    tagName: '',
    layout: _radioButton.default,
    // value - passed in, required, the value for this radio button
    // groupValue - passed in, required, the currently selected value

    // optionally passed in:
    // disabled - boolean
    // required - boolean
    // name - string
    // radioClass - string
    // radioId - string
    // ariaLabelledby - string
    // ariaDescribedby - string

    joinedClassNames: (0, _object.computed)('classNames', function () {
      var classNames = this.get('classNames');
      if (classNames && classNames.length && classNames.join) {
        return classNames.join(' ');
      }
      return classNames;
    }),
    // is this needed here or just on radio-button-input?
    defaultLayout: null,
    // ie8 support

    checkedClass: 'checked',
    checked: (0, _object.computed)('groupValue', 'value', function () {
      return (0, _utils.isEqual)(this.get('groupValue'), this.get('value'));
    }).readOnly(),
    actions: {
      changed: function changed(newValue) {
        var changedAction = this.get('changed');

        // support legacy actions
        if (typeof changedAction === 'string') {
          this.sendAction('changed', newValue);
          return;
        }

        // providing a closure action is optional
        if (changedAction) {
          changedAction(newValue);
        }
      }
    }
  });
  _exports.default = _default;
});