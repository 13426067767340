define("ember-svg-jar/inlined/icon-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 8V5c0-2.752 2.248-5 5-5s5 2.248 5 5v3h1a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2v-8a2 2 0 012-2h1zm2-3c0-1.648 1.352-3 3-3s3 1.352 3 3v3H5V5zm-3 5v8h12v-8H2z\" fill=\"#000\"/>",
    "attrs": {
      "width": "16",
      "height": "20",
      "viewBox": "0 0 16 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});