define("ember-svg-jar/inlined/candid-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M215.334 135.714l11.801 11.071c3.239 3.058 3.405 8.178.397 11.436l.033.034-60.359 65.062-.033-.033a8.012 8.012 0 01-11.371.398l-11.8-11.07c-3.24-3.059-3.438-8.179-.397-11.437l.397-.399 59.565-64.231c.132-.133.231-.299.363-.432 3.041-3.292 8.132-3.458 11.404-.399zM211.532 68.458l11.669 10.938c1.586 1.43 2.611 3.457 2.677 5.751a8.283 8.283 0 01-2.148 5.818L100.468 223.916l-.1.099c-1.388 1.53-3.371 2.494-5.619 2.56-2.28.067-4.396-.798-5.917-2.294l-11.668-10.938c-.033-.033-.1-.066-.133-.133-1.454-1.396-2.346-3.357-2.446-5.518a8.28 8.28 0 012.15-5.818L200.061 68.857a7.968 7.968 0 015.62-2.56c2.148-.067 4.165.698 5.652 2.061.099.033.132.067.198.1zM73.925 146.619l11.8 11.037c3.24 3.059 3.438 8.179.397 11.47l-32.724 35.307c-3.042 3.258-8.132 3.458-11.404.399l-11.801-11.037c-3.24-3.059-3.438-8.179-.397-11.47l32.725-35.307c3.04-3.258 8.164-3.425 11.404-.399zM140.795 2.132l11.668 10.938c1.587 1.43 2.612 3.457 2.678 5.751a8.279 8.279 0 01-2.149 5.818L29.73 157.59l-.099.099c-1.388 1.53-3.372 2.494-5.62 2.56-2.28.067-4.396-.798-5.916-2.294L6.426 147.017c-.033-.033-.099-.066-.132-.133-1.454-1.396-2.347-3.357-2.446-5.518a8.28 8.28 0 012.149-5.818L129.424 2.564a7.966 7.966 0 015.619-2.56c2.149-.066 4.165.698 5.653 2.061.033 0 .066.034.099.067zM73.858 3.03L85.66 14.1c3.24 3.059 3.405 8.179.397 11.437l.033.033L25.73 90.632l-.033-.033a8.012 8.012 0 01-11.37.399L2.558 79.96c-3.24-3.058-3.438-8.178-.397-11.436l.397-.4L62.124 3.86c.132-.133.231-.299.363-.432a7.984 7.984 0 0111.371-.399z\" fill=\"#451CCB\"/>",
    "attrs": {
      "width": "230",
      "height": "227",
      "viewBox": "0 0 230 227",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});