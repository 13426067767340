define("ember-svg-jar/inlined/icon-nav-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#a)\"><path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 2.409a.591.591 0 00-.418.173l-6.929 6.93-.279 1.114 1.115-.279 6.929-6.93A.591.591 0 0015 2.41zm-1.832-1.241a2.59 2.59 0 113.664 3.664l-7.125 7.125a1 1 0 01-.464.263l-3 .75a1 1 0 01-1.213-1.213l.75-3a1 1 0 01.263-.464l7.125-7.125zM1.232 2.732A2.5 2.5 0 013 2h5.25a1 1 0 110 2H3a.5.5 0 00-.5.5V15a.5.5 0 00.5.5h10.5a.5.5 0 00.5-.5V9.75a1 1 0 112 0V15a2.5 2.5 0 01-2.5 2.5H3A2.5 2.5 0 01.5 15V4.5a2.5 2.5 0 01.732-1.768z\" fill=\"#585151\"/></g><defs><clipPath id=\"a\"><path fill=\"#fff\" d=\"M0 0h18v18H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});