define("ember-svg-jar/inlined/plan-warn-lg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#DB8260\"><path d=\"M30 0c16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30C13.431 60 0 46.569 0 30 0 13.431 13.431 0 30 0zm0 5C16.193 5 5 16.193 5 30s11.193 25 25 25 25-11.193 25-25S43.807 5 30 5z\"/><path d=\"M36 41.908c0 3.054-2.467 5.538-5.5 5.538S25 44.962 25 41.908c0-3.054 2.467-5.539 5.5-5.539s5.5 2.485 5.5 5.539zM25.637 13.745l.935 18.83a1.655 1.655 0 001.648 1.579h4.56c.88 0 1.604-.694 1.648-1.579l.935-18.83A1.656 1.656 0 0033.715 12h-6.43c-.944 0-1.695.795-1.648 1.745z\"/></g>",
    "attrs": {
      "width": "60",
      "height": "60",
      "viewBox": "0 0 60 60",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});