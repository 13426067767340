define("ember-svg-jar/inlined/icon-payment-confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle class=\"fill-current\" cx=\"41.5\" cy=\"41.5\" r=\"41.5\" fill=\"#1AD89C\"/><path d=\"M36.78 54.838L24 42.168l4.064-3.944 8.716 8.553L55.718 28l4.066 4.03L36.78 54.839z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "83",
      "height": "83",
      "viewBox": "0 0 83 83",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});