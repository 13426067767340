define("ember-tag-input/components/tag-input", ["exports", "ember", "ember-tag-input/templates/components/tag-input"], function (_exports, _ember, _tagInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Component = _ember.default.Component,
    computed = _ember.default.computed;
  var KEY_CODES = {
    BACKSPACE: 8,
    COMMA: 188,
    ENTER: 13,
    SPACE: 32
  };
  var TAG_CLASS = 'emberTagInput-tag';
  var REMOVE_CONFIRMATION_CLASS = 'emberTagInput-tag--remove';
  var _default = Component.extend({
    layout: _tagInput.default,
    classNameBindings: [':emberTagInput', 'readOnly:emberTagInput--readOnly'],
    tagName: 'ul',
    tags: null,
    removeConfirmation: true,
    allowDuplicates: false,
    allowSpacesInTags: false,
    showRemoveButtons: true,
    readOnly: false,
    placeholder: '',
    ariaLabel: '',
    _isRemoveButtonVisible: computed('showRemoveButtons', 'readOnly', function () {
      return this.get('showRemoveButtons') && !this.get('readOnly');
    }),
    onKeyUp: false,
    addNewTag: function addNewTag(tag) {
      var tags = this.get('tags');
      var addTag = this.get('addTag');
      var allowDuplicates = this.get('allowDuplicates');
      if (!allowDuplicates && tags && tags.indexOf(tag) >= 0) {
        return false;
      }
      return addTag(tag) !== false;
    },
    didInsertElement: function didInsertElement() {
      this.initEvents();
    },
    dispatchKeyUp: function dispatchKeyUp(value) {
      if (this.get('onKeyUp')) {
        this.get('onKeyUp')(value);
      }
    },
    _onContainerClick: function _onContainerClick() {
      var newTagInput = this.element.querySelector('.js-ember-tag-input-new');
      var isReadOnly = this.get('readOnly');
      if (!isReadOnly) {
        newTagInput.focus();
      }
    },
    _onInputKeyDown: function _onInputKeyDown(e) {
      if (!this.readOnly) {
        var allowSpacesInTags = this.get('allowSpacesInTags');
        var tags = this.get('tags');
        var backspaceRegex = new RegExp(String.fromCharCode(KEY_CODES.BACKSPACE), 'g');
        var newTag = e.target.value.trim().replace(backspaceRegex, '');
        if (e.which === KEY_CODES.BACKSPACE) {
          if (newTag.length === 0 && tags.length > 0) {
            var removeTagAtIndex = this.get('removeTagAtIndex');
            if (this.get('removeConfirmation')) {
              var _tags = this.element.querySelectorAll('.' + TAG_CLASS);
              var lastTag = _tags[_tags.length - 1];
              if (lastTag && !lastTag.classList.contains(REMOVE_CONFIRMATION_CLASS)) {
                lastTag.classList.add(REMOVE_CONFIRMATION_CLASS);
                return;
              }
            }
            removeTagAtIndex(tags.length - 1);
          }
        } else {
          if (e.which === KEY_CODES.COMMA || !allowSpacesInTags && e.which === KEY_CODES.SPACE || e.which === KEY_CODES.ENTER) {
            if (newTag.length > 0) {
              if (this.addNewTag(newTag)) {
                e.target.value = '';
              }
            }
            e.preventDefault();
          }
          [].forEach.call(this.element.querySelectorAll('.' + TAG_CLASS), function (tagEl) {
            tagEl.classList.remove(REMOVE_CONFIRMATION_CLASS);
          });
        }
      }
    },
    _onInputBlur: function _onInputBlur(e) {
      var newTag = e.target.value.trim();
      if (newTag.length > 0) {
        if (this.addNewTag(newTag)) {
          e.target.value = '';
          this.dispatchKeyUp('');
        }
      }
    },
    _onInputKeyUp: function _onInputKeyUp(e) {
      this.dispatchKeyUp(e.target.value);
    },
    initEvents: function initEvents() {
      var container = this.element;
      var onContainerClick = this._onContainerClick.bind(this);
      var onInputKeyDown = this._onInputKeyDown.bind(this);
      var onInputBlur = this._onInputBlur.bind(this);
      var onInputKeyUp = this._onInputKeyUp.bind(this);
      container.addEventListener('click', onContainerClick);
      var newTagInput = this.element.querySelector('.js-ember-tag-input-new');
      newTagInput.addEventListener('keydown', onInputKeyDown);
      newTagInput.addEventListener('blur', onInputBlur);
      newTagInput.addEventListener('keyup', onInputKeyUp);
    },
    actions: {
      removeTag: function removeTag(index) {
        var removeTagAtIndex = this.get('removeTagAtIndex');
        removeTagAtIndex(index);
      }
    }
  });
  _exports.default = _default;
});