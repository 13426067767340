define("ember-svg-jar/inlined/icon-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.5.5a.75.75 0 01.75.75v1.864a2.501 2.501 0 010 4.772v7.364a.75.75 0 01-1.5 0V7.886a2.501 2.501 0 010-4.772V1.25A.75.75 0 012.5.5zm0 6.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM9.25 16a.75.75 0 00.75-.75v-1.864a2.501 2.501 0 000-4.772V1.25a.75.75 0 00-1.5 0v7.364a2.501 2.501 0 000 4.771v1.865c0 .414.336.75.75.75zm0-6.25a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5z\" fill=\"#000\"/>",
    "attrs": {
      "width": "12",
      "height": "16",
      "viewBox": "0 0 12 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});