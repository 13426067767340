define("ember-svg-jar/inlined/edit-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M29.191 5.9c.7-.7 1.835-.7 2.534 0l5.375 5.375c.7.7.7 1.834 0 2.534L20.975 29.934a1.792 1.792 0 01-1.267.524h-5.375c-.99 0-1.791-.802-1.791-1.791v-5.375c0-.476.188-.931.524-1.267L29.191 5.9zM16.125 24.034v2.841h2.841L33.3 12.542 30.458 9.7 16.125 24.034zM5.375 12.542a3.583 3.583 0 013.583-3.584h8.959a1.792 1.792 0 010 3.584H8.958v21.5h21.5v-8.959a1.792 1.792 0 013.584 0v8.959a3.583 3.583 0 01-3.584 3.583h-21.5a3.583 3.583 0 01-3.583-3.583v-21.5z\" fill=\"#3273F6\"/>",
    "attrs": {
      "width": "43",
      "height": "43",
      "viewBox": "0 0 43 43",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});