define("ember-svg-jar/inlined/icon-computer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4 10a4 4 0 014-4h32a4 4 0 014 4v22a4 4 0 01-4 4H26v4h6a2 2 0 110 4H16a2 2 0 110-4h6v-4H8a4 4 0 01-4-4V10zm36 22V10H8v22h32z\" fill=\"#B0A9A9\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});