define("ember-svg-jar/inlined/icon-share-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"stroke-current\" d=\"M5.25 6.3l3.5-1.75m-3.5 3.5l3.555 1.267M11.9 3.85a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zm0 6.3a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0zM5.6 7a1.75 1.75 0 11-3.5 0 1.75 1.75 0 013.5 0z\" stroke=\"#252222\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});