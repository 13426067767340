define("ember-svg-jar/inlined/icon-delete-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#fff\"/><path class=\"fill-current\" d=\"M8.5 6.4A1.4 1.4 0 019.9 5h4.2a1.4 1.4 0 011.4 1.4v1.4h2.8a.7.7 0 110 1.4h-.748l-.607 8.5a1.4 1.4 0 01-1.397 1.3H8.452a1.4 1.4 0 01-1.397-1.3l-.607-8.5H5.7a.7.7 0 110-1.4h2.8V6.4zm1.4 1.4h4.2V6.4H9.9v1.4zM7.852 9.2l.6 8.4h7.096l.6-8.4H7.852zm2.748 1.4a.7.7 0 01.7.7v4.2a.7.7 0 11-1.4 0v-4.2a.7.7 0 01.7-.7zm2.8 0a.7.7 0 01.7.7v4.2a.7.7 0 11-1.4 0v-4.2a.7.7 0 01.7-.7z\" fill=\"#585151\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});