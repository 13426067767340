define("ember-svg-jar/inlined/cc-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M13.75 1q.516 0 .883.367T15 2.25v9.5q0 .516-.367.883T13.75 13H1.25q-.516 0-.883-.367T0 11.75v-9.5q0-.516.367-.883T1.25 1h12.5zM1.25 2q-.102 0-.176.074T1 2.25V4h13V2.25q0-.102-.074-.176T13.75 2H1.25zm12.5 10q.102 0 .176-.074T14 11.75V7H1v4.75q0 .102.074.176T1.25 12h12.5zM2 11v-1h2v1H2zm3 0v-1h3v1H5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "14",
      "viewBox": "0 0 15 14"
    }
  };
  _exports.default = _default;
});