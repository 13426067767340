define("ember-svg-jar/inlined/icon-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M3 3a.5.5 0 000 1h4.239v.36a.5.5 0 00.5.5h1.344a.5.5 0 00.5-.5V3.5a.5.5 0 00-.5-.5H3zM3 5.86a.5.5 0 000 1h4.239v.358a.5.5 0 00.5.5h1.344a.5.5 0 00.5-.5V6.36a.5.5 0 00-.5-.5H3zM3 8.718a.5.5 0 000 1h4.239v.36a.5.5 0 00.5.5h1.344a.5.5 0 00.5-.5v-.86a.5.5 0 00-.5-.5H3z\" fill=\"#252222\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.209.441a1.82 1.82 0 00-1.82 1.82v10.978a1.82 1.82 0 001.82 1.82h6.004c.5 0 .978-.206 1.322-.57l1.676-1.772a1.82 1.82 0 00.498-1.25V2.26A1.82 1.82 0 009.89.44H2.21zm-.82 1.82a.82.82 0 01.82-.82h7.68a.82.82 0 01.82.82V11.5H9.285a1 1 0 00-1 1v1.556a.858.858 0 01-.072.003H2.209a.82.82 0 01-.82-.82V2.261z\" fill=\"#252222\"/>",
    "attrs": {
      "width": "12",
      "height": "16",
      "viewBox": "0 0 12 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});